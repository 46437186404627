<template>
	<div class="menu-escenaris">
		<label  v-for="unitat in unitats">
			<input type="radio" name="selunitat" :value="unitat.value" v-model="unitatVis" @change="updateUnitat(unitat.value)">
			<span>{{textos_lang.unitats[unitat.key][lang]}}</span>
			<div class="radio-input"></div>
		</label>
	</div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '../store/store'
export default {

  name: 'Unitat',
  computed:{
  	...Vuex.mapState(['unitats', 'unitatVis', 'textos_lang','lang']),
  	unitatVis: {
			get() {
				return store.state.unitatVis
			},
			set(value) {
				store.commit('change_unitat', value)
			}
	  }
  },
  data () {
    return {

    }
  },
  methods: {
  	updateUnitat(value){
      var key;
      if(store.state.vista){
        key = store.state.vista;
      } else {
        key = 'general'
      }
  		var app = store.state.aplicaciones.find(aplicacion => aplicacion.key == store.state.currentAppKey);
  		if(value=='immoble'){
  			store.state.capaFondo['source-layer'] = 'refs_geom';
    		store.state.capaUzeb['source-layer'] = 'full';
        store.state.capaUzeb['paint']['fill-outline-color'] = {
            "stops": [
                [
                  13.5,
                  "rgba(225, 225, 225, 0)"
                ],
                [
                  14,
                  "rgba(225, 225, 225, 1)"
                ]
            ]
        }
  		} else if( value=='sec_censal') {
  			store.state.capaFondo['source-layer'] = 'sc_geom';
    		store.state.capaUzeb['source-layer'] = 'SC';
        store.state.capaUzeb['paint']['fill-outline-color'] = "rgba(225, 225, 225, 1)"
  		}
  		var map = store.state.mapelement;

  		new Promise(resolve=> {
  			map.removeLayer('uzeb');
  			map.removeLayer('fondo');
  			map.removeSource('uzeb');
  			map.removeSource('fondo');
  			resolve()
  		}).then( function(){
  			map.addLayer(store.state.capaUzeb, store.state.textos);
  			map.addLayer(store.state.capaFondo,'uzeb');
  			store.commit('change_estiloIndicador', map);
	    	map.setPaintProperty('uzeb','fill-color',store.state.estiloIndicador);
        store.dispatch('updateAmbit', {key})
        var buttons = document.querySelectorAll('.einaDibuix .mapbox-gl-draw_ctrl-draw-btn')
        if(buttons){
          if(store.state.currentZoom<13&&store.state.unitatVis=='immoble'){
            buttons.forEach(but => but.disabled = true)
          } else {
            buttons.forEach(but => but.disabled = false)
          }
        }
	    })
    	
  	}
  }
}
</script>

<style lang="css" scoped>
</style>