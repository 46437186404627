<template>
  <div class="tabla-section">
    <template v-if="tableLoading">
      <transition name="fadein">
        <div class="loading">
          <img src="../assets/progress-circle-success.svg"/>
          <h3>{{textos_lang.tabla.cargando[lang]}}</h3>
        </div>
      </transition>
    </template>
    <div class="tabla-bar">
      <button class="boton sub-boton" @click="tablaUp()"><font-awesome-icon icon="arrow-up" /></button>
      <button class="boton sub-boton" @click="tablaMed()"><font-awesome-icon icon="minus-square" /></button>
      <button class="boton sub-boton" @click="tablaDown()"><font-awesome-icon icon="arrow-down" /></button>
    </div>
    <div class="tabla-container">
      <table>
        <thead>
          <tr>
            <!-- <th>Seleccionar</th> -->
            <th @click="update_tabla(['order_by','ep'])">{{textos_lang.tabla.ref[lang]}}</th>
            <th  @click="update_tabla(['order_by','ex'])">{{textos_lang.tabla.dir[lang]}}</th>
            <template v-if="!indicador_actual.opcions">
              <th  @click="update_tabla(['order_by',indicador_actual.codename])"v-html="textos_lang.indicadores[indicador_actual.key].name[lang]"></th>
            </template>
            <template v-else>
              <th  @click="update_tabla(['order_by',indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename])" v-html="textos_lang.indicadores[indicador_actual.key].name[lang]"></th>
            </template>
            <th>{{textos_lang.tabla.consultar[lang]}}</th>
          </tr>
        </thead>
        <tbody>
          
            <tr v-for="elem in dataTabla.data">
              <td>{{elem.ep}}</td>
              <td>{{elem.ex}}, {{elem.ez}}, {{elem.fb}}</td>
              <template v-if="!indicador_actual.opcions">
                <template v-if="indicador_actual.datatipus=='cat'">
                   <td v-if="elem[indicador_actual.codename]==null">{{textos_lang.tabla.sin[lang]}}</td>
                   <td v-for="(valor,index) in indicador_actual.valores" v-if="valor.codename == elem[indicador_actual.codename]||valor.codename.includes(elem[indicador_actual.codename])"  v-html="textos_lang.indicadores[indicador_actual.key].valores[index][lang]"></td>
                </template>
                <template v-else>
                  <td v-if="elem[indicador_actual.codename]==null">{{textos_lang.tabla.sin[lang]}}</td>
                  <td v-else v-html="formatear(elem[indicador_actual.codename]) + ' ' + textos_lang.indicadores[indicador_actual.key].unitat[lang]"></td>

                </template>
              </template>
              <template v-else>
                <template v-if="indicador_actual.opcions[indicador_actual.opcioSeleccionada].datatipus=='cat'">
                  <td v-if="elem[indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename]==null||elem[indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename]==''">{{textos_lang.tabla.sin[lang]}}</td>
                  <td v-for="(valor, index) in indicador_actual.valores" v-if="valor.codename == elem[indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename]||valor.codename.includes(elem[indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename])&&elem[indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename]!=''" v-html="textos_lang.indicadores[indicador_actual.key].valores[index][lang]"></td>
                </template>
                <template v-else>
                  <td v-if="elem[indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename]==null">{{textos_lang.tabla.sin[lang]}}</td>
                  <td v-else-if="indicador_actual.key==29&&indicador_actual.opcioSeleccionada==11" v-html="formatear(elem[indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename]) +  '%'"></td>
                  <td v-else v-html="formatear(elem[indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename]) +  ' ' +textos_lang.indicadores[indicador_actual.key].unitat[lang]"></td>
                </template>
              </template>
              <td>
                  <button class="boton sub-boton" @click="lupa(elem.ej)"><font-awesome-icon icon="search" /></button>
                  <a class="boton sub-boton" :href="'/referencia&' + elem.ej" target="_blank"><font-awesome-icon icon="external-link-square-alt" /></a>
              </td>
            </tr>
          
        </tbody>
      </table>
    </div>
    <div class="tabla-footer">
      <div class="buttonstabla">
        <button class="boton sub-boton" @click="cambioPag(configTabla.num_pag+1)":disabled="configTabla.num_pag==numpags? true : false"><font-awesome-icon icon="plus" /></button>
        <button class="boton sub-boton" @click="cambioPag(configTabla.num_pag-1)" :disabled="configTabla.num_pag==1? true : false"><font-awesome-icon icon="minus" /></button>
      </div>
      <div class="inputtabla"><input type="number" :placeholder="configTabla.num_pag" @change="cambioPag($event.target.value)" :max="numpags"> / {{numpags}}</div>
      
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Vuex from 'vuex'
import {store} from '../store/store'
import axios from 'axios'
import Vueaxios from 'vue-axios'
import numeral from 'numeral'
export default {

  name: 'Tabla',

  data () {
    return {
     
    }
  },
  computed:{
    ...Vuex.mapState(['configTabla', 'dataTabla', 'numpags','tableLoading','escenariSeleccionado', 'textos_lang','lang']),
    ...Vuex.mapGetters(['indicador_actual']),  
    
  },
  created(){
   
    //store.commit('get_dataTabla');
     
  },
  mounted() {
   
  },
  methods:{
    ...Vuex.mapMutations(['update_tabla']),
    ...Vuex.mapActions(['lupa']),
    formatear(value){
      numeral.locale('es');
      return numeral(value).format();
    },
    tablaUp(){
      document.querySelector('.tabla-bar').style.marginTop = '0px';
      document.querySelector('.tabla-section').style.height = 'calc(100% - 60px)';
      document.querySelector('.tabla-section').style.transform = 'translateY(0%)';
      document.querySelector('#map').style.height = 'calc(100% - 90px)';
      document.querySelector('.botonesright').style.bottom = '168px';
      document.querySelector('.mapboxgl-ctrl-scale').style.bottom= '3px';
      document.querySelector('.right-sidebar').style.height = 'auto';
      document.querySelector('.left-sidebar').style.height = 'auto';
      store.dispatch('updateTableRows','up').then(store.state.tableLoading=false)
     
    },
    tablaMed(){
      document.querySelector('.tabla-bar').style.marginTop = '0px';
      document.querySelector('.tabla-section').style.height = '50%';
      document.querySelector('.tabla-section').style.transform = 'translateY(0%)';
      document.querySelector('#map').style.height = 'calc(50% - 60px)';
      document.querySelector('#map').style.transition = 'all 0.3s ease-in-out';
      document.querySelector('.botonesright').style.bottom = 'calc(50% + 138px)';
      store.dispatch('updateTableRows','med').then(store.state.tableLoading=false);
      setTimeout(function(){
        store.state.mapelement.resize();
        document.querySelector('.right-sidebar').style.height = '50%';
        document.querySelector('.left-sidebar').style.height = '50%';
      },300)
    },
    tablaDown(){
      document.querySelector('.tabla-bar').style.marginTop = '-30px';
      document.querySelector('.tabla-section').style.height = '50%';
      document.querySelector('.tabla-section').style.transform = 'translateY(100%)';
      document.querySelector('#map').style.height = 'calc(100% - 90px)';
      document.querySelector('#map').style.transition = 'all 0s ease-in-out';
      document.querySelector('.botonesright').style.bottom = '168px';
      document.querySelector('.mapboxgl-ctrl-scale').style.bottom= '3px';
      document.querySelector('.right-sidebar').style.height = 'calc(100vh - 60px)'; 
      document.querySelector('.left-sidebar').style.height = 'calc(100vh - 60px)';
      store.state.mapelement.resize()
    },
    cambioPag(value){
      store.commit('update_tabla', ['num_pag',parseInt(value)])
    }
    
  }
}
</script>

<style lang="scss" scoped>

</style>