import Vue from 'vue'
import Vuex from 'vuex'
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import axios from 'axios'
import Vueaxios from 'vue-axios'
import PopupContent from '../components/Popupcontent'
import numeral from 'numeral'
import App from '../App'
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import * as turf from 'turf'
import * as testminmax from './testminmax.json'
import noUiSlider from 'nouislider'
import 'nouislider/distribute/nouislider.css'
import wNumb from 'wnumb'
import * as indicadores from './indicadores.js'
import * as aplicaciones from './aplicaciones.js'
var StaticMode = require('@mapbox/mapbox-gl-draw-static-mode');
import {textos_lang} from './textos.js'
Vue.use(Vuex)
//configuración de los números
numeral.register('locale', 'es', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'mil',
        million: 'millones',
        billion: 'billones',
        trillion: 'trillones'
    },
    ordinal : function (number) {
        return number === 1 ? 'er' : 'era';
    },
    currency: {
        symbol: '€'
    }
});
export const store = new Vuex.Store( {
    state: { 
    	lang:'ca',
    	textos_lang : textos_lang,
    	colorsense:'#dadae3',
		indicadores: indicadores.indicadores,
		indcomp: {
			3: [
				{'abs':'ef','cat':'eg'}
			],
			4: [
				{'abs':'em','cat':'en'}
			],
			5: [
				{'abs':'ed','cat':'ee'}
			],
		},
        escenaris : [
        	{key:0,title:'Actual', 																	value:'actual',temps:'actual'},
			{key:1,title:'Canvi de fusteries', 														value:'post1', temps:'post'},
			{key:2,title:'Aïllament de façanes', 													value:'post2', temps:'post'},
			{key:3,title:'Canvi de fusteries i aïllament de façanes', 								value:'post3', temps:'post'},
			{key:4,title:'Canvi de fusteries, aïllament de façanes i coberta', 						value:'post4', temps:'post'},
			{key:5,title:'Aïllament cobertes i mitjeres', 											value:'post5', temps:'post'},
			{key:6,title:'Aïllament cobertes, mitjeres i patis', 									value:'post6', temps:'post'},
			{key:7,title:'Canvi de fusteries i aïllament de façanes, cobertes, mitgeres, patis i soleres',	value:'post7', temps:'post'},
			{key:8,title:'Actual - Intervenció A', 													value:'compA', temps:'comp'},
			{key:9,title:'Actual - Intervenció B', 													value:'compB', temps:'comp'},
			{key:10,title:'Actual - Intervenció C', 												value:'compC', temps:'comp'},
			{key:11,title:'Actual - Intervenció D', 												value:'compD', temps:'comp' },
			{key:12,title:'Actual - Intervenció E', 												value:'compE', temps:'comp' },

        ],
        unitats: [
        	{key:0, title:'Immoble', 		value:'immoble'},
			{key:1, title:'Secció censal', 	value:'sec_censal'}
        ],
        menuItems: [
			{key: 0,link:'',title:'Mapa', class:'active'},
			{key: 1,link:'estad',title:'Estadístiques',class:''},
			{key: 2,link:'ajuda',title:'Ajuda', class:''}
		],
		botonesleft: [
			
			
			{key: 0,alt:'Escenari', 				icono:'project-diagram', 	apartado:'Escenari',					apps:['pro','show']},
			{key: 1,alt:'Unitat de visualització', 	icono:'city', 				apartado:'Unitat',						apps:['simple','arquitectonico']},	
			{key: 2,alt:'Indicador', 				icono:'list-ul', 			apartado:'Indicadors',					apps:['pro','simple','show','arquitectonico']},
			{key: 3,alt:'Mapes', 					icono:'list-ul', 			apartado:'Indicadors',					apps:['open']},
			{key: 4,alt:'Filtres', 					icono:'filter', 			apartado:'Filtres',						apps:['pro','simple','show','arquitectonico','open']},
			{key: 5,alt:'Àmbit d\'estudi',			icono:'eye', 				apartado:'Vista',						apps:['pro','show','arquitectonico']},
			{key: 6,alt:'Llegenda',					icono:'info', 				apartado:'Leyenda',					    apps:['open']},
			
		],
		botonesright: [
			{key:0,  icono:'share-alt', 	click:'compartir', apps:['open']},	
			{key: 1, icono:'adjust', 	    click:'cambiarfondo', apps:['open','pro','simple','show','arquitectonico']},	
			{key:2,  icono:'font', 		    click:'mostrartexto', apps:['open','pro','simple','show','arquitectonico']},
			{key:3,  icono:'limit.svg',     click:'mostrarmunicipis', apps:['open','pro','simple','show','arquitectonico']}
		],
		botonesref: [
			{key: 0,alt:'Referència', 				icono:'hashtag', 		apartado:'referencia'},	
			{key: 1,alt:'Inf.Arquitectònica', 		icono:'building', 	apartado:'arquitectonic'},
			{key: 2,alt:'Inf.Energètica', 			icono:'bolt', 				apartado:'energetic'},	
			{key: 3,alt:'Inf.Econòmica', 			icono:'euro-sign', 			apartado:'economic'},
			{key: 4,alt:'Opcions', 					icono:'cog', 			apartado:'opcions'},
			
		],
		leftSidebar:false,
		rightSidebar:true,
		currentcomponent:null,
		tituloseccio:null,
		indicadorSeleccionado:0,
		filtros:[ 'all',],
		lista_filtros:[],
		pitch: 0,
  		accessToken:'pk.eyJ1IjoidG1maXRhIiwiYSI6ImNqZTZ1NWJ0aTA0ZG8ycW1zMmE5N2d6MmkifQ.H2dfIP4L1I4hYDBwsUf9Dg',
  		attribution:'<a href="http://urbanzeb.com" target="_blank">UrbanZEB</a> | <a href="https://www.openstreetmap.org" target="_blank">© OpenStreetMap contributors</a>',
      	estilocapa: {
	          weight: 0.3,
	          color: '#cecece',
	          opacity: 1,
	          fillColor: '#fff',
	          fillOpacity: 1
	    },
	    estiloIndicador:{},
		colordefault: '#dadae3',
		fondo: 'Light',
		aplicaciones: aplicaciones.aplicaciones,
		colorFondo:{
			Light:'#dbd7d7',
			Dark:'#2B2A2A'
		},
		outlineColor:{
			Light:{
				    "stops": [
				        [
				          12.5,
				          "rgba(190, 190, 190, 0)"
				        ],
				        [
				          17,
				          "rgba(190, 190, 190, 1)"
				        ]
				    ]
				},
			Dark: {
				    "stops": [
				        [
				          12.5,
				          "rgba(99, 99, 99, 0)"
				        ],
				        [
				          17,
				          "rgba(99, 99, 99, 1)"
				        ]
				    ]
				}
		},
		capaUzeb: {
			id: 'uzeb',
        	type: 'fill',
        	source: {
				type: 'vector',
				url: 'https://tiles.urbanzeb.com/VT_AMB/data/full.json'
			},
	        'source-layer': 'full',
	        "minzoom": 8,
	        "paint": {
	        	"fill-color": '#dadae3',
	        	"fill-outline-color": {
				    "stops": [
				        [
				          12.5,
				          "rgba(190, 190, 190, 0)"
				        ],
				        [
				          17,
				          "rgba(190, 190, 190, 1)"
				        ]
				    ]
				}
	        },
	       
		},
		capaFondo: {
			id: 'fondo',
        	type: 'fill',
        	source: {
				type: 'vector',
				url: 'https://tiles.urbanzeb.com/VT_AMB/data/full.json'
			},
	        'source-layer': 'refs_geom',
	        "minzoom": 8,
	        "paint": {
	        	"fill-color": '#DBD7D7',
	        	/*[
			        	'case',
			        	//['==' ,['get','er'],null], '#ede8e8',
			        	'#dadae3'
			        	
					],*/
	        	"fill-outline-color": {
				    "stops": [
				        [
				          12.5,
				          "rgba(255, 255, 255, 0)"
				        ],
				        [
				          13,
				          "rgba(255, 255, 255, 1)"
				        ]
				    ]
				}
	        },
		},
		capaSeccionsFill: {
			id: 'seccionsfill',
        	type: 'fill',
        	source: {
				type: 'vector',
				url: 'https://tiles.urbanzeb.com/VT_AMB/data/full.json'
			},
	        'source-layer': 'sc_geom',
	        "minzoom": 8,
	        "paint": {
	        	'fill-color': '#ccc',
				'fill-opacity':0.5,
	        },
		},
		capaSeccionsLine: {
			id: 'seccionsline',
        	type: 'line',
        	source: {
				type: 'vector',
				url: 'https://tiles.urbanzeb.com/VT_AMB/data/full.json'
			},
	        'source-layer': 'sc_geom',
	        "minzoom": 8,
	        "paint": {
	        'line-color': '#4197af',
			'line-width': 2
	        },
		},
		capaIndExtra: {
			id: 'indextra',
        	type: 'fill',
        	source: {
				type: 'vector',
				url: ''
			},
	        'source-layer': '',
	        "minzoom": 10,
	        "paint": {
	        		
	        },
		},
		capaMunicipisLine: {
			id: 'municipisline',
        	type: 'line',
        	source: {
				type: 'vector',
				url: ''
			},
	        'source-layer': 'munis',
	        "minzoom": 8,
	        "paint": {
	        'line-color': '#4197af',
			'line-width': {
				"stops": [
				        [
				          8,
				          0.5
				        ],
				        [
				          16,
				          3
				        ]
				    ]
				}
	        },
		},
		menuIndicadors:'arq',
		escenariSeleccionado:0,
		poligonosDibujados:[],
		puntosDibujados:[],
		puntosEliminados:[],
		filteredFeatures:[],
		draw:'',
		configReferencia: {
			valor:0,
			escenari:1,
			llindar:0,
			hipotesi:0,
			unitat:0,
			sup:0,
			consum:0,
			comp:0
		},
		configTabla :{
			"refs_totals":true,
			"ind_vis":"el",
			"filtros":{},
			"order_by":{
				"ind":"ep",
				"how":"asc"
			},
			"ids_sel":[],
			"num_pag":1,
			"num_rows":10,
			"escenario":0,
		},
		dataTabla:{
			data:[],
			refs_totals:0,
		},
		numpags:0,
		currentAppKey:0,
		seleccions:[],
		vista: false,
		user:false,
		loading:false,
		calculant: false,
		errorloading: false,
		currentZoom: 10,
		unitatVis:'immoble',
		tableLoading:true,
		filteredSeccions:[],
		referenciasRubi: {
			'LORCA37': '9435401DF1993N',
 			'FATIMA45': '9933803DF1993S',
 			'MUMANY16': '8238004DF1983N',
 			'GAIETA17': '9343438DF1994S',
 			'NOVA1': '9332010DF1993S',
 			'RIBAS27_A': '9536201DF1993N',
 			//'CURET7': '8539002DF1983N',
 			'MOLINS43': '9229105DF1992N',
 			'ALBA25': '9640601DF1994S',
 			'SUISSA23': '7634503DF1973S',
 			'PINS15': '9840006DF1994S',
 			//'POESIA10': '9229110DF1992N',
 			//'POESIA14': '9229110DF1992N',
 			//'POESIA18': '9229110DF1992N'
		}
		
	},
    mutations: {
    	set_aplicacion (state, key) {
    		//establezco los parámetros propios de cada aplición según su key (App.vue y Ref.vue)
    		var app = state.aplicaciones.find(aplicacion => aplicacion.key == key);
    		state.capaFondo.source.url = app.urlFondo;
    		state.capaSeccionsFill.source.url = app.urlFondo;
    		state.capaSeccionsLine.source.url = app.urlFondo;
    		state.capaUzeb.source.url = `${app.url}_${state.escenariSeleccionado}.json`;
    		state.currentAppKey = key;
    	},
        change_component (state, payload) {
		    state.currentcomponent = payload
		},
		change_tituloseccio (state, payload) {
		    state.tituloseccio = payload
		},
		change_indicador (state, payload) {
			state.indicadorSeleccionado = payload;
		},
		change_estiloIndicador (state,map){
			
			//variables
			let indicador_actual = store.getters.indicador_actual;
			let opcion = indicador_actual.opcioSeleccionada;
			let datatipus;
			let codigo;
			//si tiene opciones, nos guardamos la que está seleccionada, su código y el datatipus (de la opción o del indicador)
			//si no las tiene, nos guardamos el código y datatipus del indicador
			if(typeof indicador_actual.opcions != 'undefined'){
				opcion = indicador_actual.opcions[opcion];
				codigo = opcion.codename;
				if(typeof indicador_actual.datatipus == 'undefined'){
					datatipus = opcion.datatipus
				} else {datatipus = indicador_actual.datatipus} 
			} else {
				datatipus = indicador_actual.datatipus;
				codigo = indicador_actual.codename;
			};
			//generar el estilo
			//Si el indicador es de tipo categorizado, añadimos cada unos valores posibles, con su código y su color
			if(datatipus == 'cat'){
				var valores = store.getters.indicador_actual.valores;
				state.estiloIndicador= [
				        	'match',
				        	["get", codigo],
				        ]
				valores.forEach(el => {
					state.estiloIndicador.push(el.codename,el.fillColor)
				})
				//si el indicador tiene una capa extra
				if(store.getters.indicador_actual.extra){
					//si la capa extra no está vacía, la vaciamos
					if(map.getLayer('indextra')!=undefined){
						map.removeLayer('indextra');
						map.removeSource('indextra')
					}
					//guardamos la url y source del mapa actual
					store.state.capaIndExtra.source.url = store.state.capaUzeb.source.url;	
					store.state.capaIndExtra['source-layer'] = store.state.capaUzeb['source-layer'];
					map.loadImage(
						store.getters.indicador_actual.extra.valores[0].img,
						function(err, image) {
						// Throw an error if something went wrong
						if (err) throw err;
						 
							// Declare the image
							map.addImage(store.getters.indicador_actual.extra.imgname, image);
							// Use it
							store.state.capaIndExtra['paint']['fill-pattern'] = ['match', ['get',store.getters.indicador_actual.extra.codename], store.getters.indicador_actual.extra.valores[0].codename,store.getters.indicador_actual.extra.imgname,'']
							store.state.capaIndExtra['paint']['fill-outline-color'] = 
								['match', 
									['get',store.getters.indicador_actual.extra.codename], 
									 store.getters.indicador_actual.extra.valores[0].codename,
									 '#4f4f4f',
									 'transparent']
							map.addLayer(store.state.capaIndExtra, store.state.textos);
					}
					);
					
				} else {
					if(map.getLayer('indextra')!=undefined){
						map.removeLayer('indextra');
						map.removeSource('indextra')
					}
					
				}
				state.estiloIndicador.push(state.colordefault)
				
			} else { //Si el indicador es de tipo ABSOLUTO 
				//cogemos el valor mínimo, medio y máximo (corregidos) de la opción (si las tiene) o del indicador (si no tiene opciones)	
				if(typeof indicador_actual.opcions != 'undefined' && typeof indicador_actual.min_corr == 'undefined'){
					var min = opcion.min_corr;
					var mid = opcion.med_corr;
					var max = opcion.max_corr;
				} else {
					var min = indicador_actual.min_corr;
					var mid = indicador_actual.med_corr;
					var max = indicador_actual.max_corr;
				}
				//en el array de estiloIndicador añadimos primero los casos específicos que se pintan con el color por defecto (gris)
				//y después añadimos una interpolación que vaya entre el valor mínimo -> medio -> máximo asociado a los colores down->mid->up del indicador
				//indicamos primero los casos especiales según la key del indicador y después los genéricos
				if(indicador_actual.key==3&&indicador_actual.opcioSeleccionada==1){
					state.estiloIndicador = [
			        	'case',
			        	['==' ,['get',codigo],null], state.colordefault,
			        	['==' ,['get',codigo],''], state.colordefault,
			        	['==' ,['get',codigo],0], state.colordefault,
			        	['==' ,['get',codigo],-1], state.colordefault,
			        	
			        	["interpolate",
							["linear"],
							['to-number', ["get", codigo]],
							min, indicador_actual.downcolor,
							mid, indicador_actual.midcolor,
							max, indicador_actual.upcolor,
						]
					]
				} else if(indicador_actual.key==4&&indicador_actual.opcioSeleccionada==1){
					state.estiloIndicador = [
			        	'case',
			        	['==' ,['get',codigo],1], '#70A800',
			        	['==' ,['get',codigo],null], state.colordefault,
			        	['==' ,['get',codigo],''], state.colordefault,
			        	['==' ,['get',codigo],0], state.colordefault,
			        	
			        	["interpolate",
							["linear"],
							['to-number', ["get", codigo]],
							min, indicador_actual.downcolor,
							mid, indicador_actual.midcolor,
							max, indicador_actual.upcolor,
						]
					]
				} else if([39, 40].includes(indicador_actual.key)){
					state.estiloIndicador = [
			        	'case',
			        	['==' ,['get',codigo],null], state.colordefault,
			        	['==' ,['get',codigo],''], state.colordefault,
			        	
			        	["interpolate",
							["linear"],
							['to-number', ["get", codigo]],
							0, '#3FBF6B',
							min, indicador_actual.downcolor,
							mid, indicador_actual.midcolor,
							max, indicador_actual.upcolor,
						]
					]
				} else if(indicador_actual.key==5&&indicador_actual.opcioSeleccionada==1){
					state.estiloIndicador = [
			        	'case',
			        	
			        	['==' ,['get',codigo],null], state.colordefault,
			        	['==' ,['get',codigo],''], state.colordefault,
			        	
			        	
			        	["interpolate",
							["linear"],
							['to-number', ["get", codigo]],
							0, '#00ebce',
							min, indicador_actual.downcolor,
							mid, indicador_actual.midcolor,
							max, indicador_actual.upcolor,
						]
					]
				} else if(min==null&&mid==null&&max==null) {
					state.estiloIndicador = '#dadae3'
					
				} else {
					state.estiloIndicador = [
			        	'case',
			        	['==' ,['get',codigo],null], state.colordefault,
			        	['==' ,['get',codigo],''], state.colordefault,
			        	['==' ,['get',codigo],0], state.colordefault,
			        	["interpolate",
							["linear"],
							['to-number', ["get", codigo]],
							min, indicador_actual.downcolor,
							mid, indicador_actual.midcolor,
							max, indicador_actual.upcolor,
						]
					]
				}
			}
		},
		toggle_Sidebar (state, sidebar) {
			state[sidebar] = !state[sidebar]
		},
		change_filtros (state, {valors, codename, datatipus}) {
			console.log('CHANGE FILTROS')
			var valorestabla = []
			if(valors!='todos'){
				valors.forEach(val=>valorestabla.push(val))
			}
			var filtros= store.getters.currentFiltros;
			var lista = []
			if(!state.vista){
				for(var f=1;f<filtros.length;f++){
					lista.push(filtros[f][1])
				}
			}else {
				if(filtros[2]){
					for(var f=2;f<filtros.length;f++){
						lista.push(filtros[f][1])
					}
				}
			}
			
			if(valors!='todos'){
				if(!lista.includes(codename)){
					if(datatipus=='cat'){
						filtros.push(['in',codename,...valors])
						lista.push(codename)

					} else if(datatipus =='abs'){

						filtros.push([">=", codename, parseFloat(valors[0])]);
						filtros.push(["<=", codename, parseFloat(valors[1])]);
						lista.push(codename);
					}				
				} else {
					var filindex = filtros.indexOf(filtros.filter(fil => fil[1]==codename)[0]);
					if(datatipus=='cat') {				
						filtros[filindex] = valors;
						filtros[filindex].unshift('in',codename);
					} else if (datatipus=='abs'){
						filtros[filindex] = ['>=', codename, parseFloat(valors[0])];
						filtros[filindex+1] = ['<=', codename, parseFloat(valors[1])];
					}
				}
				//cambiamos la configuración de la tabla para recargarla
				state.configTabla.filtros=filtros;
				state.configTabla.num_pag = 1;
				state.configTabla["refs_totals"] = true;
				this.commit('get_dataTabla');
			} else if (valors=='todos') {
				if(lista.includes(codename)){
					var listindex = lista.indexOf(codename)
					datatipus=='cat'?lista.splice(listindex,1):lista.splice(listindex,2)
					var filindex = filtros.indexOf(filtros.filter(fil => fil[1]==codename)[0]);
					datatipus=='cat'?filtros.splice(filindex,1):filtros.splice(filindex,2)
				}
				//cambiamos la configuración de la tabla para recargarla 
				if(state.configTabla.filtros.length==1){state.configTabla.filtros=[]}
				state.configTabla.num_pag = 1;
				state.configTabla["refs_totals"] = true;
				this.commit('get_dataTabla')
			}
			//lista
			if(!state.vista){
				state.lista_filtros = lista;
			} else {
				state.seleccions.find(sel=>sel.id_sel==state.vista).lista= lista;
			}

		},
		set_textos (state, map){
			var layers = map.getStyle().layers;
			for (var i = 0; i < layers.length; i++) {
				if (layers[i].type === 'symbol') {
					state.textos = layers[i].id;
				break;
				}
			}
		}, 
		change_defaultColor( state, estilo) {
			if(estilo=='Dark'){
				state.fondo = 'Dark'
				state.colordefault = '#424242'
			}else if(estilo=='Light'){
				state.fondo = 'Light'
				state.colordefault = '#dadae3'
			}
		},
		set_opcio(state) {
			//el orden es: CONSUM -> VALOR -> HIPOTESI -> LLINDAR -> UNITAT 
			var indicador_actual = store.getters.indicador_actual;
			var opciones = indicador_actual.indicadorOpcions;
			if(indicador_actual.tipopcions == 'valor'){
				indicador_actual.opcioSeleccionada = opciones['valor']
			} else if (indicador_actual.tipopcions == 'sup'){
				indicador_actual.opcioSeleccionada = opciones['sup']
			} else if (indicador_actual.tipopcions == 'consum'){
				indicador_actual.opcioSeleccionada = opciones['consum']
			} else if (indicador_actual.tipopcions == 'llindar'){
				indicador_actual.opcioSeleccionada = opciones['llindar']
			} else if (indicador_actual.tipopcions == 'llindar_unitat_valor'){
				if(opciones.valor==0){
					if(opciones.llindar ==0){
						indicador_actual.opcioSeleccionada = opciones['unitat']
					} else if(opciones.llindar == 1){
						indicador_actual.opcioSeleccionada = parseInt(opciones['unitat']) + 3
					}
				} else if(opciones.valor==1){
					if(opciones.llindar ==0){
						indicador_actual.opcioSeleccionada = parseInt(opciones['unitat']) + 6
					} else if(opciones.llindar == 1){
						indicador_actual.opcioSeleccionada = parseInt(opciones['unitat']) + 9
					}
				}
			} else if (indicador_actual.tipopcions == 'hipotesi'){
					indicador_actual.opcioSeleccionada = opciones['hipotesi']
			} else if (indicador_actual.tipopcions == 'hipotesi_llindar_unitat'){
				if(opciones.hipotesi==0){
					if(opciones.llindar ==0){
						indicador_actual.opcioSeleccionada = opciones['unitat']
					} else if(opciones.llindar == 1){
						indicador_actual.opcioSeleccionada = parseInt(opciones['unitat']) + 3
					}
				} else if(opciones.hipotesi==1){
					if(opciones.llindar ==0){
						indicador_actual.opcioSeleccionada = parseInt(opciones['unitat']) + 6
					} else if(opciones.llindar == 1){
						indicador_actual.opcioSeleccionada = parseInt(opciones['unitat']) + 9
					}
				}
			} else if (indicador_actual.tipopcions == 'hipotesi_llindar'){
				if(opciones.hipotesi==0){
					
						indicador_actual.opcioSeleccionada = parseInt(opciones['llindar'])
					
				} else if(opciones.hipotesi==1){
					indicador_actual.opcioSeleccionada = parseInt(opciones['llindar']) + 2
				}
			} else if (indicador_actual.tipopcions == 'hipotesi_unitat'){
				if(opciones.hipotesi==0){
					
						indicador_actual.opcioSeleccionada = parseInt(opciones['unitat'])
					
				} else if(opciones.hipotesi==1){
					indicador_actual.opcioSeleccionada = parseInt(opciones['unitat']) + 2
				}
			} else if (indicador_actual.tipopcions == 'hipotesi_unitat3'){
				if(opciones.unitat==0){
					
						indicador_actual.opcioSeleccionada = parseInt(opciones['hipotesi'])
					
				} else if(opciones.unitat==1){
					indicador_actual.opcioSeleccionada =  3
				} else if(opciones.unitat==2){
					indicador_actual.opcioSeleccionada =  4
				}
			} else if (indicador_actual.tipopcions == 'consum_hipotesi_unitat'){
				if(opciones.consum==0){
					if(opciones.hipotesi ==0){
						indicador_actual.opcioSeleccionada = opciones['unitat']
					} else if(opciones.hipotesi == 1){
						indicador_actual.opcioSeleccionada = parseInt(opciones['unitat']) + 3
					}
				} else if(opciones.consum==1){
					if(opciones.hipotesi ==0){
						indicador_actual.opcioSeleccionada = parseInt(opciones['unitat']) + 6
					} else if(opciones.hipotesi == 1){
						indicador_actual.opcioSeleccionada = parseInt(opciones['unitat']) + 9
					}
				}
			} else if (indicador_actual.tipopcions == 'consum_hipotesi_unitat_comparativa'){
				if(opciones.consum==0){
					if(opciones.hipotesi ==0){
						indicador_actual.opcioSeleccionada = opciones['unitat']
					} else if(opciones.hipotesi == 1){
						indicador_actual.opcioSeleccionada = parseInt(opciones['unitat']) + 3
					} 
				} else if(opciones.consum==1){
					if(opciones.unitat==0){
						indicador_actual.opcioSeleccionada = parseInt(opciones['hipotesi']) + 6
					} else if(opciones.unitat==1){
						indicador_actual.opcioSeleccionada = 9
					} else if(opciones.unitat==2){
						indicador_actual.opcioSeleccionada = 10
					}
				} else if(opciones.consum==2){
					indicador_actual.opcioSeleccionada = 11
				}
			} else if (indicador_actual.tipopcions == 'consum_hipotesi'){
				if(opciones.consum==0){
					
						indicador_actual.opcioSeleccionada = parseInt(opciones['hipotesi'])
					
				} else if(opciones.consum==1){
					indicador_actual.opcioSeleccionada = parseInt(opciones['hipotesi']) + 2
				}
			} else if(indicador_actual.tipopcions == 'unitat'){
				indicador_actual.opcioSeleccionada = opciones['unitat']
			}
		},
		set_filtreOpcions(state, payload){
			state.indicadores.find(indicador => indicador.key===payload.indicadorFiltro.key).opcionsFiltre = payload.opcions;
		},
		change_menuIndicadors(state,payload){
			state.menuIndicadors = payload
		},
		change_escenari(state,payload){
			state.escenariSeleccionado = payload
		},
		change_unitat(state,payload){
			state.unitatVis = payload
		},
		get_dataTabla(state) {
			var currentApp = state.aplicaciones.find(app => app.key == state.currentAppKey);
			if(state.aplicaciones.find(app => app.key == state.currentAppKey).tipo=='open'){
				return;
			}
			console.log('GETDATATABLA')
			state.configTabla.escenario = state.escenariSeleccionado;
			axios.post(`api/table_data/`,state.configTabla)
			.then(response => {
				state.dataTabla.data = [...response.data.data];
				if(response.data.refs_totals!=0){
					state.numpags = Math.ceil(response.data.refs_totals/state.configTabla.num_rows);
					state.dataTabla.refs_totals = response.data.refs_totals
				} else {
					state.numpags = Math.ceil(state.dataTabla.refs_totals/state.configTabla.num_rows)
				}
			})
			.catch(err => {console.log(err);});
		},
		update_tabla (state, payload) {
			console.log('UPDATETABLA')
			if(payload[0]=='num_pag'){
				state.configTabla[payload[0]] = payload[1]
				state.configTabla["refs_totals"] = false;
			} else if(payload[0]=='ind_vis'){
				state.configTabla[payload[0]] = payload[1]
				state.configTabla["refs_totals"] = false;
			} else if(payload[0]=="order_by"){
				function order(ind,how){
					if(state.configTabla.order_by.ind==ind){
						if(how=='asc'){
							return 'desc'
						} else {return 'asc'}
					} else {return 'asc'}
				}
				state.configTabla.order_by.how=order(payload[1],state.configTabla.order_by.how)
				state.configTabla.order_by.ind=payload[1];

			} else if(payload[0]=='num_rows'){
				state.configTabla[payload[0]] = payload[1]
			}
			this.commit('get_dataTabla')
		},
		set_minmax(state, payload) {
			var minmax;
			//si estoy en ámbito general, cojo los valores para las leyendas del archivo precargado y guardado
			if(!state.vista) {
				minmax = state.aplicaciones.find(app => app.key == state.currentAppKey).minmaxFile.default;
				
			} 
			//si estoy en ámbito personalizado, vengo de updateAmbit y le paso los valores para las leyendas calculadas para la selección
			else {
				minmax = payload
			}
			var currentApp = state.aplicaciones.find(app => app.key == state.currentAppKey);
			//cambio los valores min/max/mid_corr/max_corr/med_corr de cada uno de los indicadores
			//los valores min y max los utilizamos para crear los sliders de los filtros de indicadores con valores absolutos
			//los valores min_corr/max_corr/med_corr los utilizamos para generar las leyendas de indicadores con valores absolutos 
			Object.entries(minmax).forEach(elem=>{
				state.indicadores.forEach(ind=>{
					if(currentApp.indicadores.tots.includes(ind.key)){
						if(ind.tipus.includes('arq')){
							if(ind.opcions){
								ind.opcions.forEach(opt=>{
									if(opt.codename == elem[0]){
										Object.entries(elem[1]).forEach(val => {
											opt[val[0]] = val[1]
										})
									}
								})
							}else{
								if(ind.codename==elem[0]){
									Object.entries(elem[1]).forEach(val => {
										ind[val[0]] = val[1]
									})
								}
							}
						} else {
							if(ind.opcions){
								ind.opcions.forEach(opt=>{
									if(opt.codename == elem[0]){
										Object.entries(elem[1]).forEach(val => {
												opt[val[0]] = val[1]
										})
									}
									if(`${opt.codename}_${state.escenariSeleccionado}` == elem[0]){
										opt['min_corr'] = elem[1]['min_corr'];
										opt['max_corr'] = elem[1]['max_corr'];
										opt['med_corr'] = elem[1]['med_corr'];
									}
								})
							}else{
								if(ind.codename==elem[0]){

									Object.entries(elem[1]).forEach(val => {
										ind[val[0]] = val[1]
										
										
									})
								}
								if(`${ind.codename}_${state.escenariSeleccionado}` == elem[0]){
									ind['min_corr'] = elem[1]['min_corr'];
									ind['max_corr'] = elem[1]['max_corr'];
									ind['med_corr'] = elem[1]['med_corr'];
								}
							}
						}
					}
				})
			})
			
		},
		loading_off(state){
			state.loading = false;
		},
		tableNumRows(state, opt){
	    	var total = window.innerHeight;
	    	if(opt=='med'){
	    		total = total/2
	    	} else if(opt=='up') {
	    		total = total - 60;
	    	};
	    	
	    	state.configTabla.num_rows = Math.floor((total-96)/25)
	    },
	    cambio_source(state){
	    	console.log('cambio_source')
	    	//creo variables aplicación, indicador, mapa
	    	var currentApp = state.aplicaciones.find(app => app.key == state.currentAppKey);
	    	var indicador_actual = state.indicadores.find(indicador => indicador.key === state.indicadorSeleccionado);
	    	var newUrl, newBounds;
	    	var map = store.state.mapelement;
	    	var esp = false;
	    	//si el indicador tiene source propia, me lo guardo
			var indSource = currentApp.mapas.indicadors[indicador_actual.key];
			    //sí la tiene:
			    if(typeof indSource != 'undefined'){
			    	newUrl = indSource.url;
			    	newBounds = indSource.bounds;
			    	//le pongo su url a la capa 'uzeb' y el nombre de la capa
			    	store.state.capaUzeb.source.url = newUrl;
			    	store.state.capaUzeb['source-layer'] = indSource.layer;
			    	//elimino la capa 'uzeb' y la vuelvo a añadir, llevo el mapa a las bounds del indicador y cambio
			    	//y aplico el estilo
			    	new Promise(resolve=> {
							map.removeLayer('uzeb');
							map.removeSource('uzeb');
							resolve()
						}).then( function(){
							map.addLayer(store.state.capaUzeb, store.state.textos);
							map.fitBounds(newBounds,{speed:0.3});
						store.commit('change_estiloIndicador', map);
    				map.setPaintProperty('uzeb','fill-color',store.state.estiloIndicador);
    				})
			    }
			    //el indicador actual no tiene source propia
			    else {
			    	//la url y los bounds serán los generales de la app y la layer es 'full'
			    	newUrl = currentApp.url;
			    	newBounds = currentApp.mapAttributes.bounds;
			    	store.state.capaUzeb['source-layer'] = 'full';
			    	//si venimos de un indicador con source propia
			    	if(store.state.capaUzeb.source.url.indexOf(newUrl)==-1){
			    		//recuperamos la source general a la capa 'uzeb'
			    		store.state.capaUzeb.source.url = newUrl + '_0.json';
			    		//quitamos y añadimos la capa, reubicamos el mapa en las bounds y cambiamos y aplicamos el estilo
			    		new Promise(resolve=> {
  							map.removeLayer('uzeb');
  							map.removeSource('uzeb');
  							resolve()
  						}).then( function(){
  							map.addLayer(store.state.capaUzeb, store.state.textos);
  							map.fitBounds(newBounds);
  						store.commit('change_estiloIndicador', map);
	    				map.setPaintProperty('uzeb','fill-color',store.state.estiloIndicador);
	    				})
			    	} 
			    	// venimos de un indicador con source general
			    	else {
			    		//cambiamos y aplicamos el estilo
			    		store.commit('change_estiloIndicador', map);
	    				map.setPaintProperty('uzeb','fill-color',store.state.estiloIndicador);
			    	}
			    
			}
	    }
	},
    getters: {
    	currentApp : state => {
    		return state.aplicaciones.find(app => app.key == state.currentAppKey)
    	},
        indicador_actual: state => {
	      return state.indicadores.find(indicador => indicador.key === state.indicadorSeleccionado)
	    },
	    referencia: (state) => (el,cod) =>{
	    	return state.geojson.features.find(ref => ref.id===el[0])
	    },
	    escenarisActual: (state) => {
	    	return state.escenaris.filter(escenari => escenari.temps === 'actual')
	    },
	    escenarisPost: (state) => {
	    	return state.escenaris.filter(escenari => escenari.temps === 'post')
	    },
	    escenarisComp: (state) => {
	    	var currentApp = state.aplicaciones.find(app => app.key == state.currentAppKey)
	    	return state.escenaris.filter(escenari => escenari.temps === 'comp' && currentApp.escenarios.includes(escenari.key))
	    },
	    indicadorsArq: (state) => {
	    	if(state.currentAppKey==0){
	    		return state.indicadores.filter(indicador => indicador.tipus === 'arq')
	    	} else {
	    		return state.indicadores.filter(indicador => indicador.tipus === 'arq'&&indicador.key !=6)
	    	}
	    },
	    indicadorsEner: (state) => {
	    	return state.indicadores.filter(indicador => indicador.tipus === 'ener')
	    },
	    indicadorsEco: (state) => {
	    	return state.indicadores.filter(indicador => indicador.tipus === 'eco')
	    },
	    currentVista: (state)=> {
	    	if(state.vista){ return state.seleccions.find(sel=> sel.id_sel==state.vista)}
	    	else { return false }
	    },
		currentLista: (state)=> {
			if(state.vista) {
				return state.seleccions.find(sel=> sel.id_sel==state.vista).lista
				
			} else {
				return state.lista_filtros
			}
		},
		currentFiltros: (state) => {
			if(state.vista) {
				var seleccio = state.seleccions.find(sel => sel.id_sel==state.vista)
				return seleccio.filtro
			} else {
				return state.filtros
			}
		},
		currentFilteredSeccions: (state)=>{
			if(state.vista) {
				var seleccio = state.seleccions.find(sel => sel.id_sel == state.vista)
				return seleccio.filteredSeccions
			} else {
				return state.filteredSeccions
			}
		},
		orderSeleccions: (state) => {
			function compare(a, b) {
			  // Use toUpperCase() to ignore character casing
			  const bandA = a.id_sel;
			  const bandB = b.id_sel;

			  let comparison = 0;
			  if (bandA > bandB) {
			    comparison = 1;
			  } else if (bandA < bandB) {
			    comparison = -1;
			  }
			  return comparison;
			}

			return state.seleccions.sort(compare);
		},
		labelsCat: (state) => (key) =>{
			var indicador = state.indicadores.find(ind => ind.key == key)
			var labels = []
			indicador.valores.forEach(valor=>{
				labels.push(valor.valor)
			})
			return labels
		},
		currentLang: (state) =>{
			return state.lang
		}
		
	},
    actions: {
		addPopup(context,{map,boolean}) {
			var indicador = context.getters.indicador_actual;
			var store = context;
			var popup; 

			map.on('click','uzeb', function onClick(e){
				var elem = document.querySelector('.einaDibuix');
				var layerseccions = map.getLayer('seccionsfill');
				
    			if(elem && elem.style.display!='none'||(typeof layerseccions !== 'undefined'&&layerseccions.visibility!='none')){
    				if(popup){popup.remove().once();}
    			} else {
    				var hay = document.querySelector('.mapboxgl-popup');
    				if(hay==null){
    					popup= new mapboxgl.Popup().setLngLat(e.lngLat)
		          	.setHTML('<div id="vue-popup-content"></div>')
		          	.addTo(map)

		          	new PopupContent({
			        	store,
			          	propsData: { feature: e.features[0]},
			        }).$mount('#vue-popup-content')
			        map.getCanvas().style.cursor = 'pointer'
    				}
    				
    			} 
		        return
		        
			})
			map.on('mouseenter', 'uzeb', function () {
				var elem = document.querySelector('.einaDibuix');
				var layerseccions = map.getLayer('seccionsfill');
				if((elem && elem.style.display!='none'||typeof layerseccions !== 'undefined')){
					map.getCanvas().style.cursor = '';
				}else {
					map.getCanvas().style.cursor = 'pointer';
				}
			});
			map.on('mouseleave', 'uzeb', function () {
				map.getCanvas().style.cursor = '';
			});
		},
		mostrarOcultar(context, {mapa,capa,opcion}) {
			var visibility = mapa.getLayoutProperty(capa, 'visibility');
			if (opcion === 'ocultar') {
				mapa.setLayoutProperty(capa, 'visibility', 'none');
			} else if (opcion == 'mostrar') {
				mapa.setLayoutProperty(capa, 'visibility', 'visible');
			}
		},
		einaDibuix(context,{map}){
			//asignamos los modos del mapa a la var modes y nos traemos las constantes del modulo
    		const modes = MapboxDraw.modes;
			const Constants = require('@mapbox/mapbox-gl-draw/src/constants');
			var StaticMode = require('@mapbox/mapbox-gl-draw-static-mode');
			modes.static = StaticMode;
			//modificamos el modo dibujar punto para poder dibujar más de un punto seguido, es decir, seleccionar más de una referencia de una vez
    		modes.draw_point.onClick = function(state, e){
				this.updateUIClasses({
				    mouse: Constants.cursors.MOVE
				  });
				  state.point.updateCoordinate('', e.lngLat.lng, e.lngLat.lat);
				  this.map.fire(Constants.events.CREATE, {
				    features: [state.point.toGeoJSON()]
				  });
    		}
			//Configuramos Herramienta dibujo
		    const drawOptions = {
		    	boxSelect: false,
		    	controls: {
		    		point:true,
		    		polygon:true,
		    		trash:true,
		    			
		    			
		    	},
		    	styles: [
				    {
				      'id': 'highlight-active-points',
				      'type': 'circle',
				      'filter': ['all',
				        ['==', '$type', 'Point'],
				        ['==', 'meta', 'feature'],
				        ['==', 'active', 'true']],
				      'paint': {
				        'circle-radius': 0,
				      }
				    },
				    {
				      'id': 'points-are-blue',
				      'type': 'circle',
				      'filter': ['all',
				        ['==', '$type', 'Point'],
				        ['==', 'meta', 'feature'],
				        ['==', 'active', 'false']],
				      'paint': {
				        'circle-radius': 0,
				      }
				    },
				    // ACTIVE (being drawn)
				    // line stroke
				    {
				        "id": "gl-draw-line",
				        "type": "line",
				        "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
				        "layout": {
				          "line-cap": "round",
				          "line-join": "round"
				        },
				        "paint": {
				          "line-color": "#E35E3D",
				          "line-dasharray": [0.2, 2],
				          "line-width": 2
				        }
				    },
				    // polygon fill
				    {
				      "id": "gl-draw-polygon-fill",
				      "type": "fill",
				      "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
				      "paint": {
				        "fill-color": "#E35E3D",
				        "fill-outline-color": "#E35E3D",
				        "fill-opacity": 0.1
				      }
				    },
				    // polygon outline stroke
				    // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
				    {
				      "id": "gl-draw-polygon-stroke-active",
				      "type": "line",
				      "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
				      "layout": {
				        "line-cap": "round",
				        "line-join": "round"
				      },
				      "paint": {
				        "line-color": "#E35E3D",
				        "line-dasharray": [0.2, 2],
				        "line-width": 2
				      }
				    },
				    // vertex point halos
				    {
				      "id": "gl-draw-polygon-and-line-vertex-halo-active",
				      "type": "circle",
				      "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
				      "paint": {
				        "circle-radius": 5,
				        "circle-color": "#FFF"
				      }
				    },
				    // vertex points
				    {
				      "id": "gl-draw-polygon-and-line-vertex-active",
				      "type": "circle",
				      "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
				      "paint": {
				        "circle-radius": 3,
				        "circle-color": "#E35E3D",
				      }
				    },
				     // vertex points
				    {
				      "id": "gl-draw-polygon-and-line-midpoint-active",
				      "type": "circle",
				      "filter": ["all", ["==", "meta", "midpoint"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
				      "paint": {
				        "circle-radius": 3,
				        "circle-color": "#E35E3D",
				      }
				    },
					// INACTIVE (static, already drawn)
				    // line stroke
				    {
				        "id": "gl-draw-line-static",
				        "type": "line",
				        "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
				        "layout": {
				          "line-cap": "round",
				          "line-join": "round"
				        },
				        "paint": {
				          "line-color": "#000",
				          "line-width": 1
				        }
				    },
				    // polygon fill
				    {
				      "id": "gl-draw-polygon-fill-static",
				      "type": "fill",
				      "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
				      "paint": {
				        "fill-color": "#000",
				        "fill-outline-color": "#000",
				        "fill-opacity": 0
				      }
				    },
				    // polygon outline
				    {
				      "id": "gl-draw-polygon-stroke-static",
				      "type": "line",
				      "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
				      "layout": {
				        "line-cap": "round",
				        "line-join": "round"
				      },
				      "paint": {
				        "line-color": "#16333b",
				        "line-width": 1
				      }
				    }
				],
		    	displayControlsDefault:false,
		    	modes:modes,
		    }
		    //creamos la herramienta y la asignamos a la variable Draw
		   	store.state.draw = new MapboxDraw(drawOptions	)
    		var draw = store.state.draw
    		//añadimos la herramienta al mapa
    		new Promise( resolve=>{
    			map.addControl(draw, 'bottom-left');
    			resolve()
    		}).then(function(){
				//una vez añadida: reordenamos los botones
    			document.querySelector('.mapboxgl-ctrl-group').insertBefore(document.querySelector('.mapbox-gl-draw_point'),document.querySelector('.mapbox-gl-draw_polygon'))
    			//y desactivo los botones si el zoom es inferior a 13
    			var buttons = document.querySelectorAll('.einaDibuix .mapbox-gl-draw_ctrl-draw-btn')
    			if(store.state.currentZoom<13){
    				buttons.forEach(but => but.disabled = true)
    			}
    		})
    		//asignamos la funicón dibujo/desdibujo a los eventos de draw
    		map.on('draw.create', dibujo);
    		map.on('draw.update', dibujo);
    		map.on('draw.delete', desdibujo);
    		map.on('draw.trash', function(){})
    		//añadimos la clase .einadibuix al contenedor de los botones
    		var elem = document.querySelector('.mapboxgl-ctrl-group')
    		elem.classList.add('einaDibuix')
    		//si clicamos en un botón de dibujo que esté activado, desactivamos el modo dibujo
			var botones = document.querySelectorAll('.einaDibuix button')
			botones.forEach(but=>{
				but.onclick=function(){ 
					if(!but.classList.contains('active')){
						draw.changeMode('simple_select')
					}
				}
			})
			//función de filtro por dibujo
    		function dibujo(e){
    			//definimos las variables necesarias
    			var filteredFeatures,puntos,puntosNo,poligonos,poligono;
    			if(context.state.vista){
    				var vista = context.state.seleccions.find(sel=> sel.id_sel==store.state.vista);
    				if(!vista.filteredFeatures){vista.filteredFeatures=[]}
    				if(!vista.puntosDibujados){vista.puntosDibujados=[]}
    				if(!vista.puntosEliminados){vista.puntosEliminados=[]}
    				if(!vista.poligonos){vista.poligonos=[]}
    				if(!vista.lista){vista.lista=[]}
    				filteredFeatures = vista.filteredFeatures;
    				puntos = vista.puntosDibujados;
    				puntosNo = vista.puntosEliminados;
    				poligonos = vista.poligonos;
				} else {
    				filteredFeatures = store.state.filteredFeatures;
    				puntos = store.state.puntosDibujados;
    				puntosNo = store.state.puntosEliminados;
    				poligonos = store.state.poligonosDibujados;
    			}
    			
    			//todas las features del mapa
    			var features;
    			if(!store.state.vista) {
    				features =map.querySourceFeatures('fondo', {
					  sourceLayer: 'refs_geom'
					});
    			} else{
    				features = map.querySourceFeatures('fondo', {
    					sourceLayer:'refs_geom',
    					filter: store.getters.currentVista.filtro[1]
    				})
    			}
    			var filtros = store.getters.currentFiltros
    			var lista = store.getters.currentLista;
    			//Selección puntual
    			if(e.features[0].geometry.type=='Point'){

					//por cada punto lo comparo con todos los features del mapa y lo guardo o lo elimino de filteredFeatures
					features.forEach(feature => {
						//si la app no tiene secciones censales
						if(store.getters.currentApp.key==6) {
							if(turf.booleanPointInPolygon(e.features[0].geometry,feature)){
		    						var coincide;
		    						poligonos.forEach(pol=>{
		    							if(pol.features.includes(feature.properties.ej)){

		    								coincide = true
		    							}
		    						})
		    						if(puntos.includes(feature.properties.ej)||coincide){
		    							if(puntos.includes(feature.properties.ej)){
		    								var indexpuntos = puntos.indexOf(feature.properties.ej);
		    								puntos.splice(indexpuntos,1)	
		    							} else if(coincide){
		    								if(puntosNo.includes(feature.properties.ej)){
		    									var indexNo = puntosNo.indexOf(feature.properties.ej)
				    							puntosNo.splice(indexNo,1)
				    						} else {
			    								puntosNo.push(feature.properties.ej)
			    							}
		    							}
		    						} 
		    						else {
		    							puntos.push(feature.properties.ej)
		    							var indexNo = puntosNo.indexOf(feature.properties.ej)
		    							if(indexNo!=-1){
		    								puntosNo.splice(indexNo,1)
		    							}
		    						}
		    						
		    					}
						} else {
							if(typeof feature.properties.er != 'undefined'){
		    					if(turf.booleanPointInPolygon(e.features[0].geometry,feature)){
		    						var coincide;
		    						poligonos.forEach(pol=>{
		    							if(pol.features.includes(feature.properties.ej)){

		    								coincide = true
		    							}
		    						})
		    						if(puntos.includes(feature.properties.ej)||coincide){
		    							if(puntos.includes(feature.properties.ej)){
		    								var indexpuntos = puntos.indexOf(feature.properties.ej);
		    								puntos.splice(indexpuntos,1)	
		    							} else if(coincide){
		    								if(puntosNo.includes(feature.properties.ej)){
		    									var indexNo = puntosNo.indexOf(feature.properties.ej)
				    							puntosNo.splice(indexNo,1)
				    						} else {
			    								puntosNo.push(feature.properties.ej)
			    							}
		    							}
		    						} 
		    						else {
		    							puntos.push(feature.properties.ej)
		    							var indexNo = puntosNo.indexOf(feature.properties.ej)
		    							if(indexNo!=-1){
		    								puntosNo.splice(indexNo,1)
		    							}
		    						}
		    						
		    					}
	    					}
    					}
    					//si las tiene
    				})
    				

    			} else { //Selección polígono
    				poligono = e.features[0];
    				var polygonBoundingBox = turf.bbox(poligono)
			    	var southWest = [polygonBoundingBox[0], polygonBoundingBox[1]];
			        var northEast = [polygonBoundingBox[2], polygonBoundingBox[3]];
			        var northEastPointPixel = map.project(northEast);
			        var southWestPointPixel = map.project(southWest);
			        //me guardo el polígono
			        if(poligonos) {
			        	var polindex = poligonos.indexOf(poligonos.find(pol=>pol.id==e.features[0].id));
			        	if(polindex!=-1){
			        		poligonos.splice(polindex,1)
			        	}
			        }
					
					poligonos.push({'id':e.features[0].id, 'features':[],'geometry':e.features[0].geometry});
					//con turf, veo qué features intersectan con en polígono y guardo sus ids (o refs) en filteredFeatures y en su polígono
					var thispol = poligonos.find(pol => pol.id==e.features[0].id)
			        features.forEach(feature => {
			        	if(typeof feature.properties.er === 'string'|| !feature.properties.er){
				            if (! (null === turf.intersect(feature, poligono))) {
								thispol.features.push(feature.properties.ej);
				             	if(puntosNo.includes(feature.properties.ej)){
				             		var indexNo= puntosNo.indexOf(feature.properties.ej)
				             		puntosNo.splice(indexNo,1)
				             	}
				            }
				        } else {
				        	
				        }

			            
			        });
			        

    			}
    			if(puntos.length>0||poligonos.length>0){
    				filteredFeatures=[];
    				poligonos.forEach(pol=>{
	    				pol.features.forEach(feat=>{
	    					if(!puntosNo.includes(feat)){
	    						filteredFeatures.push(feat)
	    					}
	    				})
	    			})
	    			puntos.forEach(punto=>{filteredFeatures.push(punto)})
	    			
	    			//añado las ids de secciones censales correspondientes 
	    			features.forEach(feature=>{
	    				if(filteredFeatures.includes(feature.properties.ej)&&!filteredFeatures.includes(feature.properties.er)&&feature.properties.er){
	    					filteredFeatures.push(feature.properties.er)
	    				}
	    			})
	    			//si ya existiera, elimino el filtro por id(o ref) para volverlo a crear
	    			var filindex = filtros.indexOf(filtros.filter(fil => fil[1]=='ej')[0]);
			        if(filindex!=-1){
			        	filtros.splice(filindex,1)
			        }
			        //lo vuelvo a crear y añado todas los features de filteredFeatures
			        var filtroEp = ['in','ej']
			        filtroEp.push(...filteredFeatures)
			        //lo añado a los filtros
			        filtros.push(filtroEp)
			         //añado el código a la lista de filtros
		        	if(!lista.includes('ej')){lista.push('ej')}
    			} else {
    				var filindex = filtros.indexOf(filtros.filter(fil => fil[1]=='ej')[0]);
			        if(filindex!=-1){
			        	filtros.splice(filindex,1)
			        }
			        var index = lista.indexOf('ej');
			        lista.splice(index, 1)
			        filteredFeatures = [];
			        puntosNo=[];
			        //quito el código de la lista de filtros
			        var listaindex = lista.indexOf('ej');
			        if(listaindex>-1){
			        	lista.splice(listaindex,1)
			        }

    			}
    			//filtro el mapa
    			
		        map.setFilter("uzeb", filtros);
		       
		    }
		    function desdibujo(e) {
		    	//definimos las variables necesarias
    			var filteredFeatures,puntos,puntosNo,poligonos,poligono;
    			if(context.state.vista){
    				var vista = context.state.seleccions.find(sel=> sel.id_sel==store.state.vista);
    				if(!vista.filteredFeatures){vista.filteredFeatures=[]}
    				if(!vista.puntosDibujados){vista.puntosDibujados=[]}
    				if(!vista.puntosEliminados){vista.puntosEliminados=[]}
    				if(!vista.poligonos){vista.poligonos=[]}
    				if(!vista.lista){vista.lista=[]}
    				filteredFeatures = vista.filteredFeatures;
    				puntos = vista.puntosDibujados;
    				puntosNo = vista.puntosEliminados;
    				poligonos = vista.poligonos;
				} else {
    				filteredFeatures = store.state.filteredFeatures;
    				puntos = store.state.puntosDibujados;
    				puntosNo = store.state.puntosEliminados;
    				poligonos = store.state.poligonosDibujados;
    			}
		    	var filtros = store.getters.currentFiltros
    			var lista = store.getters.currentLista;
		    	
		    	e.features.forEach(feature => {


			    	var poligono = feature;
			    	
			    	var polindex = poligonos.indexOf(poligonos.find(pol=>pol.id==feature.id));
			        if(polindex!=-1){
			        	poligonos.splice(polindex,1)
			        }
			    	var filindex = filtros.indexOf(filtros.filter(fil => fil[1]=='ej')[0]);
			        if(filindex!=-1){
			        	filtros.splice(filindex,1)
			        }
			        if(poligonos.length==0&&puntos.length==0){
			        	var index = lista.indexOf('ej');
			        	lista.splice(index, 1)
			        } else {
			        	filteredFeatures=[]
		    			poligonos.forEach(pol=>{
		    				pol.features.forEach(feat=>{
		    					if(!puntosNo.includes(feat)){
		    						filteredFeatures.push(feat)
		    					}
		    				})
		    			})
		    			puntos.forEach(punto=>{filteredFeatures.push(punto)})
			        	var filtroEj = ['in','ej']
			        	filtroEj.push(...filteredFeatures)
			        	filtros.push(filtroEj)
			    	}
		    	})
		        map.setFilter("uzeb", filtros);

		    }
		    
		},
		
		updateAmbit(context, {key}){
			console.log('UPDATEAMBIT')
		    //defino vars
		    var map = store.state.mapelement;
			var seleccio = context.state.seleccions.find(sel=> sel.id_sel==key);
			var draw = store.state.draw;
			//si hay algún dibujo, lo elimino
			if(draw!=''){
				draw.deleteAll()
			}
			//si estoy en ámbito general
			if(key=='general'){
				//actualizo: vista, minmax, filtros
	  			context.state.vista = false
	  			store.commit('set_minmax')
	  			map.setFilter('uzeb', context.state.filtros)
	  			map.setFilter('fondo')
	  			//filtro la tabla y actualizo la tabla
	  			if(context.state.filtros.length==1){
	  				context.state.configTabla.filtros=[];
	  			} else {
	  				context.state.configTabla.filtros=context.state.filtros;
	  			}
	  			context.state.configTabla.num_pag = 1;
				context.state.configTabla["refs_totals"] = true;
				context.commit('get_dataTabla');
				//actualizo los filtros
				store.dispatch('updateFiltros', map)
				//dibujo los polígonos que tuviera guardados
				var poligonos = store.state.poligonosDibujados;
				if(poligonos){poligonos.forEach(pol=>{draw.add(pol.geometry)})}
				/*
				Me dificulta la comparativa de valores entre escenarios que se reseteen los bounds.. que te parece?
				map.fitBounds(context.getters.currentApp.mapAttributes.bounds)
				*/
	  		} 
	  		//si estoy en ámbito de selección personalizada
	  		else {
	  			//función para actualizar la selección y las leyendas
	  			function successlegend(seleccio,response){
	  				map.fitBounds(context.getters.currentApp.mapAttributes.bounds)
	  				//actualizo las versiones de filtro y leyenda de la selección
	  				seleccio.filtro_ver = response.data.seleccion_object.filtro_ver;
		            seleccio.leyenda_ver = response.data.seleccion_object.leyenda_ver;
		            seleccio.num_features = response.data.seleccion_object.num_features;
		            //actualizo los minmax, filtros y la tabla
		            store.commit('set_minmax', response.data.seleccion_object.leyenda);
		            context.state.configTabla.filtros=context.getters.currentVista.filtro;
				    context.state.configTabla.num_pag = 1;
				    context.state.configTabla["refs_totals"] = true;
				    context.commit('get_dataTabla');
				    //actulaizo los filtros y los aplico
				    store.dispatch('updateFiltros', map)
				    map.setFilter('uzeb', context.getters.currentVista.filtro)
				    map.setFilter('fondo', response.data.seleccion_object.filtro)
				    //dibujo los polígonos que tuviera guardados
				    var poligonos = context.getters.currentVista.poligonos;
				    if(poligonos){poligonos.forEach(pol=>{draw.add(pol.geometry)})};
				    
				    setTimeout(function(){
				    	var features = map.queryRenderedFeatures({layers:['uzeb']});
						var ne = ['no','no'];
						var sw = ['no','no'];
						features.forEach(feat=>{
							if(feat.geometry.type=='Polygon'){
								feat.geometry.coordinates.forEach(pol =>{
									pol.forEach(coord=> {

										if(coord[0]>ne[0]||ne[0]=='no'){
											ne[0] = coord[0]
										}
										else if(coord[0]<sw[0]||sw[0]=='no'){
											sw[0] = coord[0]
										}
										if(coord[1]>ne[1]||ne[1]=='no'){
											ne[1] = coord[1]
										}
										else if(coord[1]<sw[1]||sw[1]=='no'){
											sw[1] = coord[1];
										}
									})
								})
							}
							else if(feat.geometry.type=='MultiPolygon'){
								feat.geometry.coordinates.forEach(multi =>{
									multi.forEach(pol=>{
										pol.forEach(coord=> {

											if(coord[0]>ne[0]||ne[0]=='no'){
												ne[0] = coord[0]
											}
											if(coord[0]<sw[0]||ne[0]=='no'){
												sw[0] = coord[0]
											}
											if(coord[1]>ne[1]||ne[1]=='no'){
												ne[1] = coord[1]
											}
											if(coord[1]<sw[1]||sw[1]=='no'){
												sw[1] = coord[1]
											}
										})
									})
								})
							}
							
							
						});
						map.fitBounds([ne,sw])
					},1500)
				    
	  			}
	  			context.state.loading = true;
	  			context.state.calculant = true;
	  			context.state.vista = key
	  			var data = {
		          'id_sel': parseInt(key),
		        }
		        var csrftoken = store.dispatch('getCookie', 'csrftoken')
		        //llamo a la api para actualizar la selección y sus leyendas
		        axios.post(`api/update_legend/`, data, {headers:{'X-CSRFToken':csrftoken}})
		        .then(response => { 
		        	if(response.data.seleccion_object){
		        		const succpromise = new Promise(resolve => {
			        		successlegend(seleccio,response);
			        		resolve()
			        	});
			        	succpromise.then(context.state.loading = false, context.state.calculant = false)	
		        	} else {
		        		store.state.calculant = false
			         	store.state.errorloading = true;
			         	setTimeout(function() {
			         		context.state.loading = false;
			         		context.state.errorloading = false
							     
						}, 3000)
			         	
		        	}
		        	
		         
		        })
		         .catch(err => {
		         	console.log(err);
		         	const error = new Promise(resolve =>{
		         		calculant = false
		         		store.state.errorloading = true;
		         		setTimeout(() => {
						    resolve() // Change status to 'fulfilled'
						  }, 4000)
		         	})
		         	error.then(context.state.loading = false, context.state.errorloading = false)
		         });

	  		}
	  		
	  		
	  	},
	  	updateFiltros(context, map){
	  		console.log('UPDATEFILTROS')
	  		//reseteo todos los filtros
	  		//selecciono todos los checkbox
	  		var allcheck = document.querySelectorAll(`.menu-filtres input[type="checkbox"]`)
	  		allcheck.forEach(input=>input.checked = true)
	  		//elimino los sliders y los vuelvo a crear con los nuevos valores
	  		var sliders = document.querySelectorAll('.noUi-target')
	  		sliders.forEach(slider=>{slider.noUiSlider.destroy()})
	  		store.dispatch('crearSliders', map)
	  		//activo todos los sliders
	  		var sliders = document.querySelectorAll('.noUi-target')
	  		var max = document.querySelectorAll('.maxinput')
	  		var min = document.querySelectorAll('.mininput')
	  		sliders.forEach(slider=>{slider.removeAttribute('disabled')})
	  		max.forEach(maxx=>{maxx.disabled = false})
	  		min.forEach(minn=>{minn.disabled = false})
	  		//activo todos los inputs
	  		var inputs = document.querySelectorAll('.lista-filtre input[type="checkbox"]')
	  		inputs.forEach(inp => inp.disabled = false)
	  		//si estamos viendo un ámbito personalizado
	  		if(context.state.vista){
	  			//recojo los filtros del ámbito
	  			var filtros = context.getters.currentVista.filtro;
	  			//desactivo los checkbox que determinan el ámbito (dentro de filtros[1])
	  			filtros[1].forEach(fil=>{
	  				for(var f=1;f<fil.length;f++){
	  					if(fil[f][0]=="in"){
	  						var ins = document.querySelectorAll(`.menu-filtres input[name="${fil[f][1]}"][type="checkbox"]`)
	  						ins.forEach(inp=>{
	  							var arrayvalue = inp.value.split(',');
	  							arrayvalue.forEach(val=>{
	  								if(!fil[f].includes(val)){
	  									inp.checked = false;
		  								inp.disabled = true
		  							}
	  							})
	  							
	  						})
	  					}
	  				}
	  			})
	  			//aplico y deselecciono los filtros aplicados en este ámbito (además de los que lo determinan, a partir de filtros[2])
  				filtros.forEach(fil=>{
  					//datatipus=cat
  					if(fil[0]=='in'&&fil[1]!='ej'){
  						//recojo la key del indicador correspondiente
  						var indkey; 
  						store.state.indicadores.forEach(indicador=>{
							if(indicador.opcions){
								indicador.opcions.forEach(opcio=>{
									if(opcio.codename==fil[1]){
										indkey= indicador.key
									}
								})
							} 
						})
  						//deselecciono todos los inputs y selecciono aquellos que están en el array del filtro
  						var inputs = document.querySelectorAll(`.menu-filtres input[name=${fil[1]}]`)
  						inputs.forEach(inp=>inp.checked=false)
  						fil.forEach(val=>{
  							if(fil.indexOf(val)>1){
  								inputs.forEach(input=>{
  									if(input.value.includes(val)&&val!=''){
  										input.checked=true
  									} 
  								})
  							}
  						})
  						if(context.state.indcomp[indkey]){
							document.querySelector(`#SL${context.state.indcomp[indkey][0].abs}`).setAttribute('disabled',true);
							document.querySelector(`#min${context.state.indcomp[indkey][0].abs}`).disabled = true;
							document.querySelector(`#max${context.state.indcomp[indkey][0].abs}`).disabled = true
						}
  						
					} else if(fil[0]=='in'&&fil[1]=='ej'){
						
					}
					//datatipus=abs
					else if(fil[0]==">="){
						//busco su input de rango y le cambio el valor
						document.querySelector(`.menu-filtres input[id="min${fil[1]}"]`).value = fil[2]
						document.querySelector(`#SL${fil[1]}`).noUiSlider.set([fil[2], null]);
					} else if(fil[0]=="<="){
						//busco su input de rango y le cambio el valor
						document.querySelector(`.menu-filtres input[id="max${fil[1]}"]`).value = fil[2]
						document.querySelector(`#SL${fil[1]}`).noUiSlider.set([null,fil[2]]);
					}
					
					
  				})
	  			
	  		} else { //si estamos en ámbito general
	  			//recojo los filtros aplicados en este ámbito durante la sesión (no los que determinan el ámbito)
	  			var filtros = context.state.filtros;
	  			
	  				filtros.forEach(fil=>{
	  					//datatipus=cat
	  					if(fil[0]=='in'&&fil[1]!='ej'){
	  						//recojo la key del indicador correspondiente
	  						var indkey; 
	  						store.state.indicadores.forEach(indicador=>{
								if(indicador.opcions){
									indicador.opcions.forEach(opcio=>{
										if(opcio.codename==fil[1]){
											indkey= indicador.key
										}
									})
								} 
							})
							//deselecciono todos los inputs y selecciono aquellos que están en el array del filtro
	  						var inputs = document.querySelectorAll(`.menu-filtres input[name=${fil[1]}]`)
	  						inputs.forEach(inp=>inp.checked=false)
	  						fil.forEach(val=>{
	  							if(fil.indexOf(val)>1){
	  								inputs.forEach(input=>{
	  									if(input.value.includes(val)&&val!=''){
	  										input.checked=true
	  									} 
	  								})
	  							}
	  						})
	  						if(store.state.indcomp[indkey]){
								document.querySelector(`#SL${store.state.indcomp[indkey][0].abs}`).setAttribute('disabled',true);
								document.querySelector(`#min${store.state.indcomp[indkey][0].abs}`).disabled = true;
								document.querySelector(`#max${store.state.indcomp[indkey][0].abs}`).disabled = true
							}
	  						
						}
						else if(fil[0]=='in'&&fil[1]=='ej'){

						}
						//datatipus=abs
						else if(fil[0]==">="){
							//busco su input de rango y le cambio el valor
							document.querySelector(`.menu-filtres input[id="min${fil[1]}"]`).value = fil[2]
							document.querySelector(`#SL${fil[1]}`).noUiSlider.set([fil[2], null]);
						} else if(fil[0]=="<="){
							//busco su input de rango y le cambio el valor
							document.querySelector(`.menu-filtres input[id="max${fil[1]}"]`).value = fil[2]
							document.querySelector(`#SL${fil[1]}`).noUiSlider.set([null,fil[2]]);
						}
						
	  				})
	  			 
	  		}
	  	},
	  	crearSliders(context,map) {
	  		store.state.indicadores.forEach(indicador => {
	  			if(store.getters.currentApp.indicadores.tots.includes(indicador.key)){
					if(typeof indicador.opcions == 'undefined'|| indicador.datatipus =='abs'){
	  					if(typeof indicador.opcions == 'undefined' && indicador.datatipus =='abs'){
							let slider = document.querySelector(`#SL${indicador.codename}`);
							crear(indicador.codename,slider,indicador.key,map)
						} else if(typeof indicador.opcions != 'undefined' && indicador.datatipus =='abs') {
							indicador.opcions.forEach(opcion => {
								let slider = document.querySelector(`#SL${opcion.codename}`)
								crear(opcion.codename,slider,indicador.key,map)
							})
							
						}		
					} else if(typeof indicador.datatipus == 'undefined' && typeof indicador.opcions != 'undefined') {
						indicador.opcions.forEach(opcion=> {
							if(opcion.datatipus=='abs'){
								let slider = document.querySelector(`#SL${opcion.codename}`)
								crear(opcion.codename,slider,indicador.key,map)
							}
							
						})
					}
	  			}
				
				
						
			})
			function crear(codename,slider,key,map){
				var ind = store.state.indicadores.find(indicador=> indicador.key == key)
				var tipus= ind.tipus
				var atr;
				if(tipus=='arq'){
					if(ind.opcions){
						atr = ind.opcions.find(opcion=>opcion.codename==codename)
					} else {
						atr = ind
					}
				} else {	
					if(ind.opcions){
						atr = ind.opcions.find(opcion=>opcion.codename==codename)
					} else {
						atr = ind
					}
				}
				
				var min = atr.min;
				var max = atr.max;
				var nodata = false
				if(min==null&&max==null){
					nodata = true;
				}
				var unitat = store.state.textos_lang.indicadores[key].unitat[store.state.lang];
				if(!nodata){
					document.getElementById(`SL${codename}`).innerHTML='';
					document.getElementById(`min${codename}`).style.display = 'block';
					document.getElementById(`max${codename}`).style.display = 'block';
					document.querySelectorAll(`.input${codename}`).forEach(inp=>{inp.style.display='grid'})
					if(key!=3){
						noUiSlider.create(slider, {
						    start: [min, max],
						    connect:true,
						    direction:'rtl',
						    orientation: 'vertical',
						    tooltips: [wNumb({suffix:` ${unitat}`,decimals: 2, thousand:'.', mark:','}),wNumb({suffix:` ${unitat}`,decimals: 2, thousand:'.', mark:','})],
						    range: {
						        'min': [min],
						        'max': [max]
						    }
						});
					} else  {
						//para "Any de construcción" (key=3), el formato de los números máximo y mínimo es diferente (sin decimales ni punto)
						noUiSlider.create(slider, {
						    start: [min, max],
						    connect:true,
						    direction:'rtl',
						    orientation: 'vertical',
						    tooltips: [wNumb({suffix:` ${unitat}`,decimals: 0, }),wNumb({suffix:` ${unitat}`,decimals: 0, })],
						    range: {
						        'min': [min],
						        'max': [max]
						    }
						});
					}
					
					slider.noUiSlider.on('set', function () {
						var datatipus = 'abs';
				        var valors = slider.noUiSlider.get();
				        if(valors[0]==parseFloat(slider.noUiSlider.options.range.min[0]).toFixed(2)&&valors[1]==parseFloat(slider.noUiSlider.options.range.max[0]).toFixed(2)){valors='todos'}
				        store.commit('change_filtros', {codename,valors,datatipus});
				    	if(store.state.indcomp[key]){
				    		var inputs = document.querySelectorAll(`input[name=${store.state.indcomp[key][0].cat}]`)
				    		if(valors!='todos'){
				    			inputs.forEach(inp=> {inp.disabled = true})
				    			document.querySelector(`.checkall.${store.state.indcomp[key][0].cat}`).disabled = true
				    		} else {
				    			inputs.forEach(inp=> {inp.disabled = false})
				    			document.querySelector(`.checkall.${store.state.indcomp[key][0].cat}`).disabled = false;
				    		}
				    	}
				        map.setFilter('uzeb', store.getters.currentFiltros)
				    });
				    var mininput = document.getElementById(`min${codename}`);
				    var maxinput = document.getElementById(`max${codename}`);
				    slider.noUiSlider.on('update', function(values,handle){
				    	
				    	var value = values[handle];
				    	if(key==3){
				    		if (handle) {
						        maxinput.value = parseInt(value);
						    } else {
						        mininput.value = parseInt(value);
						    }
				    	} else {
				    		if (handle) {
						        maxinput.value = parseFloat(value).toFixed(2);
						    } else {
						        mininput.value = parseFloat(value).toFixed(2);
						    }
				    	}
					    
				    });
				    mininput.addEventListener('change', function () {
					    slider.noUiSlider.set([this.value, null]);
					});

					maxinput.addEventListener('change', function () {
					    slider.noUiSlider.set([null, this.value]);
					});
				}
				
				if(nodata){
					document.getElementById(`SL${codename}`).innerHTML=store.state.textos_lang.filtres.nohay[store.state.lang];
					document.getElementById(`min${codename}`).style.display = 'none';
					document.getElementById(`max${codename}`).style.display = 'none';
					document.querySelectorAll(`.input${codename}`).forEach(inp=>{inp.style.display='none'})
				}

			}
	  	},
	  	getCookie(context, name) {
	      var value = "; " + document.cookie;
	      var parts = value.split("; " + name + "=");
	      if (parts.length == 2) return parts.pop().split(";").shift();
	    },
	    async updateTableRows(context, opt){
	    	await context.commit('tableNumRows',opt);
	    	await context.commit('get_dataTabla')
	    },
	    getDefs(context, {filtro,key}) {
			filtro.forEach((all,index)=>{
				if (index < 1) return;
				all.forEach(fil=>{
					state.indicadores.forEach(indicador=>{
						new Promise(resolve=>{
							if(!indicador.opcions){
								if(indicador.codename==fil[1]){
									var yasta = false;
									store.state.selections_defs.forEach(def=>{
										if(def.name==indicador.name){
											yasta = true;
										}
									})
									if(!yasta){
										store.state.selections_defs.push({'key':key,'name':indicador.name,'valors':[],'rango':[]})	
									}
									
								}
							} else {
								indicador.opcions.forEach(opcio=>{
									if(opcio.codename==fil[1]){
										var yasta = false;
										store.state.selections_defs.forEach(def=>{
											if(def.name==indicador.name){
												yasta = true;
											}
										})
										if(!yasta){
											store.state.selections_defs.push({'name':indicador.name,'subtitle':opcio.subtitle,'valors':[],'rango':[]})	
										}
										
									}
								})
							};
							resolve()
						}).then(function(){
							if(fil[0]=='in'){

								var elem = store.state.selections_defs.find(def=>def.name==indicador.name)
								if(elem){
									var suind= store.state.selections_defs.indexOf(elem)
									for(var x=2;x<fil.length;x++){
										store.state.selections_defs[suind].valors.push(fil[x])
									}
								}
								
							}
						})
						
					})
				})
			})
		},
		lupa(context,ref){
	      axios.post(`api/get_bbox/`,{'ej':ref})
	      .then(response => {
	      	var bbox = [response.data.bbox.ne,response.data.bbox.sw]
	        context.state.mapelement.fitBounds(bbox)
	        
	      })
	      .catch(err => {console.log(err);});
	    },
	    
    }
})
