<template>
	<transition name="slideright">
        <div class="left-sidebar" :class="currentApp.tipo" v-show="leftSidebar" ref="leftbar">
            <div class="boton sub-boton cerrar-leftbar" @click="closeLeftbar"><font-awesome-icon icon="times" /></div>
            <div class="seccio-leftbar">
				<h3 class="titulo-seccio" v-html="textos_lang.botonesleft[tituloseccio][lang]" v-if="currentcomponent!='Leyenda'"></h3>
				<Indicadors v-show="currentcomponent=='Indicadors'"/>
				<Filtres ref="filtres" v-show="currentcomponent=='Filtres'"/>
				<Escenari 	v-show="currentcomponent=='Escenari'"/>
				<Vista v-if="user" v-show="currentcomponent=='Vista'"/>
				<Unitat v-show="currentcomponent=='Unitat'"/>
				<Leyenda v-show="currentcomponent=='Leyenda'"/>
		    </div>
        </div>
    </transition>
</template>

<script>
import Vuex from 'vuex'
import Indicadors from './Indicadors'
import Filtres from './Filtres'
import Escenari from './Escenari'
import Vista from './Vista'
import Unitat from './Unitat'
import Leyenda from './Leyenda'
export default {

  	name: 'Leftbar',
  	components: {Indicadors, Filtres, Escenari,Vista, Unitat, Leyenda},
  	computed: {
  	...Vuex.mapState(['currentcomponent','tituloseccio', 'leftSidebar','user','textos_lang','lang']),
  	...Vuex.mapGetters(['currentApp'])
  	},
	methods: {
		closeLeftbar() {
			this.$store.commit('toggle_Sidebar', 'leftSidebar');
			var botones = document.getElementsByClassName('botonesleft')[0].children;
			document.querySelector('.mapboxgl-ctrl-bottom-left').style.left='0px';
			for(var b=0; b<botones.length; b++){
		    	botones[b].style.background='#4197af';
		    }
		    var menuindicadors = this.$children[0].$refs.menuindicadors;
		    if(typeof menuindicadors != 'undefined') {
		    	menuindicadors.style.transform = 'translateX(0px)';
		    }
			var menufiltres = this.$children[0].$refs.menufiltres;
		    if(typeof menufiltres != 'undefined') {
		    	menufiltres.style.transform = 'translateX(0px)';
		    }


		}
	}
}
</script>

<style lang="scss">
</style>