//titulos
var tit = {
    var : ' Variació respecte a l\'escenari actual',
    cte : ' Llindar confort',
    sal : ' Llindar salut',
    ele : ' Hipòtesi electricitat',
    gas : ' Hipòtesi gas',
    tot : ' Per secció',
    pi : ' Per immoble',
    ph : ' Per habitatge',
    pm : ' Per m<sup>2</sup>',
    mi : ' Moda immobles',
    mh : ' Moda habitatges',
    sr : ' Sobre rasant',
    ss : ' Sota rasant',
    si : ' Indeterminada'
}
export const indicadores = [
        	{key:0, name: 'Ús principal', codename:'el', tipus:'arq', datatipus:'cat', unitatVisualitzacio:['immoble','sec_censal'],
        		valores:[
					{fillColor:'#00C29D', codename:['Residencial','Comu','VincViv']},
					{fillColor:'#74C24E', codename:['EnsenyamentCultural', 'Public']},
					{fillColor:'#E1C25A', codename:'HotelerRestauracio'},
					{fillColor:'#FFB866', codename:'Esportiu'},
					{fillColor:'#F79463', codename:'Piscines'},
					{fillColor:'#CC5C52', codename:'Comercial'},
					{fillColor:'#B0466A', codename:'Oficines'},
					{fillColor:'#776483', codename:'Aparcament'},
					{fillColor:'#5B7490', codename:'Emmagatzematge'},
					{fillColor:'#418FA3', codename:['Industrial','IndustrialResta']},
					{fillColor:'#4EAFC2', codename:['AltresCal','AltresNoCal','NoCodificat']},
					{fillColor:'#dadae3', codename:['Sense dades','Sense','','None','null','undefined',' ']}
				],
				fuente:'Catastro de España Febrero 2019',escenaris:[0,1,2,3,4,5]	
			},
			{key:1, name: 'Tipus d\'immoble residencial',	codename:'ek',			tipus:'arq', datatipus:'cat', unitatVisualitzacio:['immoble','sec_censal'],
				valores:[
					{fillColor:'#004666',	codename:'IMMB_EXC_V'},
					{fillColor:'#0085A8', 	codename:'IMMB_PRN_75_V'},
					{fillColor:'#00B8EA', 	codename:'IMMB_PRN_50_V'},
					{fillColor:'#6EE1FF',	codename:'IMMB_AMB_V'},
					{fillColor:'#ccccd5',	codename:'IMMB_NO_V'},
					{fillColor:'#dadae3',	codename:['Sense dades','Sense','','None']},
					],escenaris:[0,1,2,3,4,5]
			},
			{key:2, name: 'Tipus de propietat residencial', 	codename:'ew',			tipus:'arq', datatipus:'cat', unitatVisualitzacio:['immoble','sec_censal'],
				valores:[
					{fillColor:'#0085A8', codename:'P'},				
					{fillColor:'#70A800', codename:'U'},
					{fillColor:'#dadae3', codename:['Sense dades','Sense','','None']},
				],escenaris:[0,1,2,3,4,5]
			},
			{key:3, name: 'Any de construcció de la part residencial', 		tipus:['arq','gen'], unitat:'', unitatVisualitzacio:['immoble','sec_censal'],
				opcions:[
						{key:0 , datatipus:'cat',  codename:'eg', subtitle:'Categoritzat'},						
						{key:1 , datatipus:'abs',  codename:'ef', max_corr: '', med_corr: '', min_corr:'', min:'', max: '', subtitle:'Absolut'}
				],
				valores: [
					{fillColor:'#000807', codename:'08EN'},
      				{fillColor:'#00302a', codename:'8107'},
      				{fillColor:'#00544a', codename:'6180'},
      				{fillColor:'#00a28f', codename:'4160'},
      				{fillColor:'#73dfd5', codename:'0140'},
      				{fillColor:'#d9f7f4', codename:'FS00'},
      				{fillColor:'#dadae3', codename:['Sense dades','Sense','','None','SD']}],
				upcolor: '#000807', midcolor: '#00A28F', downcolor: '#d9f7f4', 
				tipopcions:'valor', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				}, 
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				fuente:'Catastro de España Febrero 2019',escenaris:[0,1,2,3,4,5]
			},
			{key:4, name: 'Nombre d\'habitatges', tipus:['arq','gen'], unitat:'habitatges', unitatVisualitzacio:['immoble','sec_censal'],
				opcions:[
					{key:0, datatipus:'cat', codename:'en',subtitle:'Categoritzat'},
					{key:1, datatipus:'abs',codename:'em',max_corr:62, med_corr: 32, min_corr:2, min:2, max: 62, subtitle:'Absolut'}
				],
				valores:[
					{fillColor:'#033247',	codename:'P_40mes'},
					{fillColor:'#064766',	codename:'P_20a39'},
					{fillColor:'#1385a6',	codename:'P_10a19'},
					{fillColor:'#1eb9e8',	codename:'P_5a9'},
					{fillColor:'#73e1fd', 	codename:'P_2a4'},
					{fillColor:'#3FBF6B', 	codename:['P_CORR', 'P_Corr']},
					{fillColor:'#70A800',	codename:'U'},
					{fillColor:'#dadae3',	codename:['Sense dades','Sense','None','NR']}],
				upcolor: '#033247', midcolor: '#1385a6', downcolor: '#73e1fd', 
				tipopcions:'valor', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				fuente:'Catastro de España Febrero 2019',escenaris:[0,1,2,3,4,5]
			},
			{key:5, name: 'Nombre de plantes', tipus:['arq','gen'], unitat:'plantes', unitatVisualitzacio:['immoble','sec_censal'],
				opcions:[
					{key:0, datatipus:'cat',  codename:'ee',subtitle:'Categoritzat'},
					{key:1, datatipus:'abs',  codename:'ed',max_corr: '', med_corr: '', min_corr:'',max: '',  min:'',subtitle:'Absolut'}
				],
				valores:[
					{fillColor:'#007063', 	codename:'De PB+3 o mes'},
					{fillColor:'#00A28E', 	codename:'De PB a PB+2'},
					{fillColor:'#00ebce',	codename:'De P-1 en avall'},
					{fillColor:'#dadae3',	codename:['Sense dades','Sense','','None', 'NR']}],
				upcolor: '#00352e', midcolor: '#007e6e', downcolor: '#00A28E', 
				tipopcions:'valor',opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				fuente:'Catastro de España Febrero 2019',escenaris:[0,1,2,3,4,5]
			},
			{key:6, name: 'Tipus d\'ordenació urbanística', codename:'eo',	tipus:'arq',datatipus:'cat', unitatVisualitzacio:['immoble','sec_censal'],
				valores:[
					{fillColor:'#004666',	codename:'EAI'},
					{fillColor:'#0085A8', 	codename:'EAV'},
					{fillColor:'#00B8EA', 	codename:'EFI'},
					{fillColor:'#6EE1FF',	codename:'EVE'},
					{fillColor:'#828282',	codename:'IND'},
					{fillColor:'#ccccd5',	codename:'NO_V'},
					{valor:'Sense dades', 			 	fillColor:'#dadae3',	codename:['Sense dades','Sense','','None']}],
				escenaris:[0,1,2,3,4,5]
			},
			{key:7, name: 'Clúster tipològic', 	codename:'ei',	tipus:'arq',datatipus:'cat', unitatVisualitzacio:['immoble','sec_censal'],
				valores:[
					{fillColor:'#004F0B', codename:'A'},
					{fillColor:'#4B7000', codename:'B'},
					{fillColor:'#609000', codename:'C'},
					{fillColor:'#76b000', codename:'D'},
					{fillColor:'#8bd000', codename:'E'},
					{fillColor:'#BBEF00', codename:'F'},
					{fillColor:'#6ee1ff', codename:'G'},
					{fillColor:'#5cbbd4', codename:'H'},
					{fillColor:'#4996aa', codename:'I'},
					{fillColor:'#377180', codename:'J'},
					{fillColor:'#254b55', codename:'K'},
					{fillColor:'#12252a', codename:'L'},
					{fillColor:'#dadae3', codename:['Sense dades','Sense','','None']}],escenaris:[0,1,2,3,4,5]
			},
			{key:8, name: 'Superfície construïda', tipus:'arq', unitat:'m<sup>2</sup>', datatipus:'abs', unitatVisualitzacio:['immoble','sec_censal'],
				opcions:[
					{key:0, codename:'eu',max_corr: 6974.0, med_corr: 3513.0, min_corr:53.0,max: 6974.0, min:53.0,},
					{key:1, codename:'es',max_corr: 5924.0, med_corr: 2983.5, min_corr:43.0,max: 5924.0, min:43.0,},
					{key:2, codename:'et',max_corr: 2989.0, med_corr: 1505.5, min_corr:22.0,max: 2989.0, min:22.0,}
				],
				upcolor:'#003547', midcolor:'#00698e', downcolor:'#009cd4', 
				tipopcions: 'sup',opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},escenaris:[0,1,2,3,4,5]
			},
			{key:9, name:'Superfície residencial construïda', tipus:'arq', codename:'ev', unitat:'m<sup>2</sup>', datatipus:'abs', unitatVisualitzacio:['immoble','sec_censal'], old_codename:'sup_viv_sbr',
				upcolor:'#005744', midcolor:'#008165', downcolor:'#00ac86', max_corr: 3434.0, med_corr: 1743.0, min_corr:52.0,max: 3434.0, min:52.0
				,escenaris:[0,1,2,3,4,5]
			},
			
			//ENERGÈTICS


			{key:10, name:'Temperatura interior', tipus:'ener',codename:'bm', datatipus:'abs', unitat:'graus', unitatVisualitzacio:['immoble','sec_censal'],
				upcolor:'#640000', midcolor:'#ffe600',downcolor:'#00a28e', max_corr:14.55, med_corr:13.56, min_corr:12.57, max:14.55,  min:12.57,
				escenaris:[0,1,2,3,4,5]
			},
			{key:11, name:'Autonomía tèrmica', tipus:'ener', datatipus: 'abs', unitat:'hores', unitatVisualitzacio:['immoble','sec_censal'],
				opcions: [
					{key:0,  max_corr:3789, med_corr:3482, min_corr:3176,max:3789, min:3176,codename:'bn',},
					{key:1,  max_corr:4528, med_corr:4181, min_corr:3834,max:4528, min:3834,codename:'bo',},					
				],
				upcolor:'#004666', midcolor:'#3f8192',downcolor:'#6ee1ff',
				tipopcions: 'llindar', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				escenaris:[0,1,2,3,4,5]
			},
			{key:26, name: 'Transmitància tèrmica global', tipus:'ener', unitat:'W/m<sup>2</sup>·K', datatipus:'abs', unitatVisualitzacio:['immoble','sec_censal'],codename:'ck',
				max_corr:715855.77 , 	med_corr:370638.42 , 	min_corr:25421.07, max:715855.77 ,  	min:25421.07,
				upcolor: '#1c5847', midcolor: '#38b08e', downcolor: '#7acab4',escenaris:[0,1,2,3,4,5]
				
			},
			{key:43, name:'Autonomía tèrmica', tipus:'ener', datatipus: 'abs', unitat:'hores', unitatVisualitzacio:['immoble','sec_censal'],
				 max_corr:3789, med_corr:3482, min_corr:3176,max:3789, min:3176,codename:'bn',
				upcolor:'#004666', midcolor:'#3f8192',downcolor:'#6ee1ff',
				escenaris:[0,1,2,3,4,5]
			},
			{key:12, name:'Salt tèrmic', tipus:'ener', datatipus: 'abs', unitat:'graus·dia', unitatVisualitzacio:['immoble','sec_censal'],
				opcions: [
					{key:0, max_corr:37542.47, med_corr:27697.23, min_corr:2619.85, max:37542.47, min:2619.85,  codename:'bk',},
					{key:1, max_corr:21580.97, med_corr:17424.95, min_corr:13268.93,max:21580.97, min:13268.93, codename:'bl',},					
				],
				upcolor:'#640000', midcolor:'#ffe600',downcolor:'#00a28e',
				tipopcions: 'llindar', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				escenaris:[0,1,2,3,4,5]
			},
			{key:44, name:'Salt tèrmic', tipus:'ener', datatipus: 'abs', unitat:'graus·dia', unitatVisualitzacio:['immoble','sec_censal'],
				max_corr:37542.47, med_corr:27697.23, min_corr:2619.85, max:37542.47, min:2619.85,  codename:'bk',
				upcolor:'#640000', midcolor:'#ffe600',downcolor:'#00a28e',
				escenaris:[0,1,2,3,4,5]
				
			},
			{key:13, name:'Demanda de calefacció', tipus:'ener', unitat:"kWh", unitatTemps:'any', unitatVisualitzacio:['immoble','sec_censal'],
				opcions: [
					{key:0,    datatipus: 'cat',codename:'cal',},
      				{key:1,    datatipus: 'cat',codename:'cak',},
      				{key:2,    datatipus: 'cat',codename:'cam',},
      				{key:3,    datatipus: 'cat',codename:'cao',},
      				{key:4,    datatipus: 'cat',codename:'can',},
      				{key:5,    datatipus: 'cat',codename:'cap',},
      				{key:6,    datatipus: 'abs',codename:'al', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
      				{key:7,    datatipus: 'abs',codename:'ak', max_corr:31649.89 ,	med_corr:18465.87 , 	min_corr:5281.85 ,	max:31649.89 , 		min:5281.85 },
      				{key:8,    datatipus: 'abs',codename:'am', max_corr:224.93 , 	med_corr:145.695 , 		min_corr:66.46 ,	max:224.93 , 	 	min:66.46 },
      				{key:9,    datatipus: 'abs',codename:'ao', max_corr:106061.06 , 	med_corr:55039.43 , 	min_corr:4017.8 ,	max:106061.06 ,  	min:4017.8 },
      				{key:10,   datatipus: 'abs',codename:'an', max_corr:15147.85 , 	med_corr:8698.64 , 		min_corr:2249.43 ,	max:15147.85 ,  	min:2249.43 },
      				{key:11,   datatipus: 'abs',codename:'ap', max_corr:114.43 , 	med_corr:70.235 , 		min_corr:26.04 ,	max:114.43 ,  		min:26.04 },
				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#004f0b',
				tipopcions:'llindar_unitat_valor', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				valores: [
		            
		            {fillColor:"#640000", codename:[">135",">180"]},
		            {fillColor:"#992700", codename:["120-135","160-180"]},
		            {fillColor:"#cf5000", codename:["105-120","140-160"]},
		            {fillColor:"#fca600", codename:["90-120","120-140"]},
		            {fillColor:"#ffe600", codename:["75-90","100-120"]},
		            {fillColor:"#c8fe00", codename:["60-75","80-100"]},
		            {fillColor:"#8fd600", codename:["45-60","60-80"]},
		            {fillColor:"#70a800", codename:["30-45","40-60"]},
		            {fillColor:"#4b7000", codename:["15-30","20-40"]},
		            {fillColor:'#004f0b', codename:["<15","<20"]},
		            {fillColor:"#dadae3", codename:'null'},
		    	],
    			escenaris:[0,1,2,3,4,5]	
			},
			{key:45, name:'Demanda de calefacció', tipus:'ener', unitat:"kWh", unitatTemps:'any', unitatVisualitzacio:['immoble','sec_censal'],
				opcions: [
					{key:0,    datatipus: 'abs',codename:'al', max_corr:143605.76 , med_corr:77321.45 , 	min_corr: 11037.2,	max:143605.76 ,  	min: 11037.2},
      				{key:1,    datatipus: 'abs',codename:'ak', max_corr:11037.2 ,	med_corr:5988.67 , 	min_corr:940.14 ,	max:11037.2 , 		min:940.14 },
      				{key:2,    datatipus: 'abs',codename:'am', max_corr:224.93 , 	med_corr:145.695 , 		min_corr:66.46 ,	max:224.93 , 	 	min:66.46 },
      			],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#004f0b',
				tipopcions:'unitat', opcioSeleccionada:1,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:1
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
    			escenaris:[0,1,2,3,4,5]
			},
			{key:27, name:'Demanda de refrigeració', tipus:'ener', unitat:"kWh", unitatTemps:'any', unitatVisualitzacio:['immoble','sec_censal'],
				opcions: [
					{key:0,    datatipus: 'abs',codename:'bp', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
      				{key:1,    datatipus: 'abs',codename:'bq', max_corr:31649.89 ,	med_corr:18465.87 , 	min_corr:5281.85 ,	max:31649.89 , 		min:5281.85 },
      				{key:2,    datatipus: 'abs',codename:'br', max_corr:224.93 , 	med_corr:145.695 , 		min_corr:66.46 ,	max:224.93 , 	 	min:66.46 },
      			],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#004f0b',
				tipopcions:'unitat', opcioSeleccionada:1,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:1
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				escenaris:[0,1,2,3,4,5]
    			
			},
			{key:14, name:'Qualificació energética - Demanda de calefacció', tipus:'ener', datatipus:'cat',codename:'bd', unitatVisualitzacio:['immoble','sec_censal'],
				valores:[
					{fillColor:'#4b7000', codename:'A'},
					{fillColor:'#70a800', codename:'B'},
					{fillColor:'#c8fe00', codename:'C'},
					{fillColor:'#ffe600', codename:'D'},
					{fillColor:'#fca600', codename:'E'},
					{fillColor:'#cf5000', codename:'F'},
					{fillColor:'#992700', codename:'G'},
					{fillColor:'#dadae3', codename:'Sense'}
				],
				escenaris:[0,1,2,3,4,5]
			},
			{key:47, name:'Qualificació energética - Demanda de calefacció', tipus:'ener',codename:'bd', datatipus:'cat', unitatVisualitzacio:['immoble','sec_censal'],
				valores:[
					{fillColor:'#4b7000', codename:'A'},
					{fillColor:'#70a800', codename:'B'},
					{fillColor:'#c8fe00', codename:'C'},
					{fillColor:'#ffe600', codename:'D'},
					{fillColor:'#fca600', codename:'E'},
					{fillColor:'#cf5000', codename:'F'},
					{fillColor:'#992700', codename:'G'},
					{fillColor:'#dadae3', codename:'Sense'}
				],
				
				escenaris:[0,1,2,3,4,5]
			},
			{key:28, name:'Qualificació energética - Demanda de refrigeració', codename:'bs',tipus:'ener', datatipus:'cat', unitatVisualitzacio:['immoble','sec_censal'],
				valores:[
					{fillColor:'#4b7000', codename:'A'},
					{fillColor:'#70a800', codename:'B'},
					{fillColor:'#c8fe00', codename:'C'},
					{fillColor:'#ffe600', codename:'D'},
					{fillColor:'#fca600', codename:'E'},
					{fillColor:'#cf5000', codename:'F'},
					{fillColor:'#992700', codename:'G'},
					{fillColor:'#dadae3', codename:'Sense'}
				],
				
				escenaris:[0,1,2,3,4,5]
			
			},
			{key:15, name: 'Consum de calefacció', tipus:'ener',datatipus:'abs',unitat:'kWh', unitatVisualitzacio:['immoble','sec_censal'],
				opcions: [
					{key:0,		codename:'c', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr:9276.2 , 	max:237028.7 , 	 	min:9276.2 },
 					{key:1,		codename:'a', max_corr:31649.89 , 	med_corr:18465.87 , 	min_corr:5281.85 , 	max:31649.89 , 	 	min:5281.85 },
 					{key:2,		codename:'b', max_corr:224.93 , 	med_corr:145.695 , 		min_corr:66.46 , 	max:224.93 , 	 	min:66.46 },
 					{key:3,		codename:'f', max_corr:296285.88 , 	med_corr:153940.565 , 	min_corr:11595.25 , max:296285.88 ,   	min:11595.25 },
 					{key:4,		codename:'d', max_corr:39562.36 , 	med_corr:23082.335 ,	min_corr:6602.31 , 	max:39562.36 , 		min:6602.31 },
 					{key:5,		codename:'e', max_corr:281.16 , 	med_corr:182.115 , 		min_corr:83.07 , 	max:281.16 , 	 	min:83.07 },
 					{key:6,		codename:'i', max_corr:106061.06 , 	med_corr:55039.43 , 	min_corr:4017.8 , 	max:106061.06 ,  	min:4017.8 },
 					{key:7,	  	codename:'g', max_corr:15147.85 , 	med_corr:8698.64 , 		min_corr:2249.43 , 	max:15147.85 , 	 	min:2249.43 },
 					{key:8,	 	codename:'h', max_corr:114.43 , 	med_corr:70.235 , 		min_corr:26.04 , 	max:114.43 , 	 	min:26.04 },
 					{key:9,	  	codename:'l', max_corr:132576.32 , 	med_corr:68799.28 , 	min_corr:5022.24 , 	max:132576.32 ,  	min:5022.24 },
 					{key:10,	codename:'j', max_corr:18934.81 , 	med_corr:10873.3 , 		min_corr:2811.79 , 	max:18934.81 , 		min:2811.79 },
 					{key:11,	codename:'k', max_corr:143.03 , 	med_corr:87.785 , 		min_corr:32.54, 	max:143.03 , 	 	min:32.54 }
				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#004f0b',
				tipopcions:'hipotesi_llindar_unitat', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				escenaris:[0,1,2,3,4,5]
			},
			{key:29, name: 'Consum d\'energia final de calefacció', tipus:'ener', unitat:'kWh', unitatTemps:'any', unitatVisualitzacio:['immoble','sec_censal'],
				opcions:[
					{key:0,    datatipus: 'abs',codename:'c', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:1,    datatipus: 'abs',codename:'a', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:2,    datatipus: 'abs',codename:'b', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:3,    datatipus: 'abs',codename:'i', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:4,    datatipus: 'abs',codename:'g', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:5,    datatipus: 'abs',codename:'h', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:6,    datatipus: 'abs',codename:'bv', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:7,    datatipus: 'abs',codename:'cm', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:8,    datatipus: 'abs',codename:'pv', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:9,    datatipus: 'abs',codename:'px', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:10,    datatipus: 'abs',codename:'py', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:11,    datatipus: 'abs',codename:'pn', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					
				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#004f0b', 
				tipopcions: 'consum_hipotesi_unitat_comparativa',
				opcioSeleccionada:1,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:1,
					consum:0,
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0,
				},
				escenaris:[0,1,2,3,4,5]
			},
			{key:16, name:'Qualificació energètica - Consum de calefacció', tipus:'ener', datatipus:'cat', unitatVisualitzacio:['immoble','sec_censal'],
				opcions: [
					{key:0, subtitle: tit.ele, codename:'be',},
					{key:1, subtitle: tit.gas, codename:'bf',},
				],
				valores:[
					{fillColor:'#4b7000', codename:'A'},
					{fillColor:'#70a800', codename:'B'},
					{fillColor:'#c8fe00', codename:'C'},
					{fillColor:'#ffe600', codename:'D'},
					{fillColor:'#fca600', codename:'E'},
					{fillColor:'#cf5000', codename:'F'},
					{fillColor:'#992700', codename:'G'},
					{fillColor:'#dadae3', codename:'Sense'}
				],
				tipopcions:'hipotesi', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				escenaris:[0,1,2,3,4,5]
			},
			{key:48, name:'Qualificació energètica - Consum de calefacció', tipus:'ener', datatipus:'cat', unitatVisualitzacio:['immoble','sec_censal'],
				opcions: [
					{key:0, subtitle: tit.ele, codename:'be',},
					{key:1, subtitle: tit.gas, codename:'bf',},
					{key:2, subtitle: tit.ele, codename:'ea',},
					{key:3, subtitle: tit.gas, codename:'eb',}
				],
				valores:[
					{fillColor:'#4b7000', codename:'A'},
					{fillColor:'#70a800', codename:'B'},
					{fillColor:'#c8fe00', codename:'C'},
					{fillColor:'#ffe600', codename:'D'},
					{fillColor:'#fca600', codename:'E'},
					{fillColor:'#cf5000', codename:'F'},
					{fillColor:'#992700', codename:'G'},
					{fillColor:'#dadae3', codename:'Sense'}
				],
				tipopcions:'consum_hipotesi', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				escenaris:[0,1,2,3,4,5]
			},
			{key:33, name:'Qualificació energética - Consum de refrigeració', tipus:'ener', datatipus:'cat',codename:'bt', unitatVisualitzacio:['immoble','sec_censal'],
				valores:[
					{fillColor:'#4b7000', codename:'A'},
					{fillColor:'#70a800', codename:'B'},
					{fillColor:'#c8fe00', codename:'C'},
					{fillColor:'#ffe600', codename:'D'},
					{fillColor:'#fca600', codename:'E'},
					{fillColor:'#cf5000', codename:'F'},
					{fillColor:'#992700', codename:'G'},
					{fillColor:'#dadae3', codename:'Sense'}
				],
				opcions: [
					{key:0,codename:'bt'},
					{key:1,codename:'ec'},
					
				],
				tipopcions:'consum', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0,
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				escenaris:[0,1,2,3,4,5]
			
			},
			
			{key:17, name: 'Consum total', tipus:'ener', datatipus:'abs', unitat:'kWh', unitatVisualitzacio:['immoble','sec_censal'],
				opcions: [
					{key:0,		 codename:'o',max_corr:424706.55 , 	med_corr:220008.915 , 	min_corr:15311.28 ,		max:424706.55 ,  	min:15311.28 },
 					{key:1,		 codename:'m',max_corr:37476.69 , 	med_corr:24292.67 , 	min_corr:11108.65 ,		max:37476.69 , 	 	min:11108.65 },
 					{key:2,		 codename:'n',max_corr:313.9 , 		med_corr:211.76 , 		min_corr:109.62 ,		max:313.9 , 	 	min:109.62 },
 					{key:3,		 codename:'r',max_corr:205062.63 , 	med_corr:106174.565 , 	min_corr:7286.5 ,		max:205062.63 ,  	min:7286.5 },
 					{key:4,		 codename:'p',max_corr:18228.25 , 	med_corr:11779.04 ,	    min_corr:5329.83 ,		max:18228.25 , 		min:5329.83 },
 					{key:5,		 codename:'q',max_corr:161.04 , 	med_corr:104.945 , 		min_corr: 48.85 ,	max:161.04 , 	 	min: 48.85 },
 					{key:6,		 codename:'u',max_corr:484252.11 , 	med_corr:250962.705 , 	min_corr:17673.3 ,		max:484252.11 ,  	min:17673.3 },
 					{key:7,	  	 codename:'s',max_corr:145389.16 , 	med_corr:28909.135 , 	min_corr:12429.11 ,		max:145389.16 ,  	min:12429.11 },
 					{key:8,	  	 codename:'t',max_corr:366.67 , 	med_corr:247.56 , 		min_corr:128.45 ,	max:366.67 , 	 	min:128.45 },
 					{key:9,	  	 codename:'x',max_corr:231901.75 , 	med_corr:120110.515 , 	min_corr:8319.28 ,		max:231901.75 ,  	min:8319.28 },
 					{key:10,	 codename:'v',max_corr:22015.21 , 	med_corr:13953.7 , 		min_corr:5892.19 ,		max:22015.21 , 	 	min:5892.19 },
 					{key:11,	 codename:'w',max_corr:187.88 , 	med_corr:122.16 , 		min_corr:56.44,		max:187.88 , 	 	min:56.44 }
				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#004f0b',
				tipopcions:'hipotesi_llindar_unitat', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				escenaris:[0,1,2,3,4,5]
			},
			/*{key:34, name: 'Consum real d\'energia final total per habitatge', tipus:'ener', unitat:'', datatipus:'abs', unitatVisualitzacio:['immoble','sec_censal'],
				
				opcions: [
					{key:0,		 codename:'cx',max_corr:424706.55 , 	med_corr:220008.915 , 	min_corr:15311.28 ,		max:424706.55 ,  	min:15311.28 },
 					{key:1,		 codename:'cz',max_corr:37476.69 , 	med_corr:24292.67 , 	min_corr:11108.65 ,		max:37476.69 , 	 	min:11108.65 },
 					{key:2,		 codename:'db',max_corr:313.9 , 		med_corr:211.76 , 		min_corr:109.62 ,		max:313.9 , 	 	min:109.62 },
 					{key:3,		 codename:'dc',max_corr:205062.63 , 	med_corr:106174.565 , 	min_corr:7286.5 ,		max:205062.63 ,  	min:7286.5 },
 				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#004f0b',
				tipopcions:'hipotesi_unitat', opcioSeleccionada:1,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:1
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				
				escenaris:[0,1,2,3,4,5]
				
				
			},*/
			{key:34, name: 'Consum real d\'energia final total per habitatge', tipus:'ener', unitat:'', unitatTemps:'any', datatipus:'abs', unitatVisualitzacio:['immoble','sec_censal'],
				
				opcions: [
					{key:0,		 codename:'cx',max_corr:313.9 , 		med_corr:211.76 , 		min_corr:109.62 ,		max:313.9 , 	 	min:109.62 },
 					{key:1,		 codename:'cz',max_corr:205062.63 , 	med_corr:106174.565 , 	min_corr:7286.5 ,		max:205062.63 ,  	min:7286.5 },
 					{key:2,		 codename:'bx',max_corr:205062.63 , 	med_corr:106174.565 , 	min_corr:7286.5 ,		max:205062.63 ,  	min:7286.5 },
 					{key:3,		 codename:'da',max_corr:205062.63 , 	med_corr:106174.565 , 	min_corr:7286.5 ,		max:205062.63 ,  	min:7286.5 },
 					{key:4,		 codename:'po',max_corr:205062.63 , 	med_corr:106174.565 , 	min_corr:7286.5 ,		max:205062.63 ,  	min:7286.5 },
 					//{key:5,		 codename:'pq',max_corr:205062.63 , 	med_corr:106174.565 , 	min_corr:7286.5 ,		max:205062.63 ,  	min:7286.5 },
 					//{key:6,		 codename:'bx',max_corr:205062.63 , 	med_corr:106174.565 , 	min_corr:7286.5 ,		max:205062.63 ,  	min:7286.5 },
 					//{key:7,		 codename:'da',max_corr:205062.63 , 	med_corr:106174.565 , 	min_corr:7286.5 ,		max:205062.63 ,  	min:7286.5 },
 					//{key:8,		 codename:'po',max_corr:205062.63 , 	med_corr:106174.565 , 	min_corr:7286.5 ,		max:205062.63 ,  	min:7286.5 },
 				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#004f0b',
				tipopcions:'hipotesi_unitat3', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				
				escenaris:[0,1,2,3,4,5]
				
				
			},
			{key:30, name: 'Emissions de CO2 vinculades al consum de calefacció', tipus:'ener', unitat:'no lo sé',unitatTemps:'any', datatipus:'', unitatVisualitzacio:['immoble','sec_censal'],
				opcions:[
					{key:0,    datatipus: 'abs',codename:'ci', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:1,    datatipus: 'abs',codename:'dn', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:2,    datatipus: 'abs',codename:'dp', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:3,    datatipus: 'abs',codename:'cj', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:4,    datatipus: 'abs',codename:'do', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:5,    datatipus: 'abs',codename:'dq', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:6,    datatipus: 'abs',codename:'pr', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:7,    datatipus: 'abs',codename:'pt', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:8,   datatipus: 'abs',codename:'qf', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:9,   datatipus: 'abs',codename:'qg', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:10,   datatipus: 'abs',codename:'qh', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},

				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#004f0b',
				tipopcions: 'consum_hipotesi_unitat_comparativa',
				opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				escenaris:[0,1,2,3,4,5]
			},
			{key:18, name: 'Cost econòmic de la calefacció', tipus:'eco',datatipus:'abs',unitat:'€', unitatVisualitzacio:['immoble','sec_censal'],
				opcions: [
					{key:0,		codename:'aa', max_corr:27721.69 , 	med_corr:14403.295 , 	min_corr:1084.9 ,		max:27721.69 , 	 	min:1084.9 },
 					{key:1,		codename:'y',  max_corr:3701.61 , 	med_corr:2159.675 , 	min_corr:617.74 ,		max:3701.61 , 	 	min:617.74 },
 					{key:2,		codename:'z',  max_corr:26.31 , 	med_corr:17.04 , 		min_corr:7.77 ,			max:26.31 , 	 	min:7.77 },
					{key:3,		codename:'ag', max_corr:12404.37 , 	med_corr:6437.135 , 	min_corr:469.9 ,		max:12404.37 , 	 	min:469.9 },
 					{key:4,		codename:'ae', max_corr:1771.62 , 	med_corr:1017.35 ,		min_corr:263.08 ,		max:1771.62 , 		min:263.08 },
 					{key:5,		codename:'af', max_corr:13.38 , 	med_corr:8.21 , 		min_corr:3.04 ,			max:13.38 ,  		min:3.04 },
					{key:6,		codename:'ad', max_corr:13732.46 , 	med_corr:7135.885 , 	min_corr:539.31 ,		max:13732.46 , 	 	min:539.31 },
 					{key:7,	  	codename:'ab', max_corr:1833.66 , 	med_corr:1069.835 , 	min_corr:306.01 ,		max:1833.66 , 	 	min:306.01 },
 					{key:8,	  	codename:'ac', max_corr:13.03 , 	med_corr:8.465 , 		min_corr:3.9 ,			max:13.03 , 	 	min:3.9 },
					{key:9,	  	codename:'aj', max_corr:6951.62 , 	med_corr:3600.95 , 		min_corr:250.28 ,		max:6951.62 , 	 	min:250.28 },
 					{key:10,	codename:'ah', max_corr:877.6 , 	med_corr:514.47 , 		min_corr:151.34 ,		max:877.6 , 	 	min:151.34 },
 					{key:11,	codename:'ai', max_corr:6.65 , 		med_corr:4.155 , 		min_corr:1.66,			max:6.65 , 		 	min:1.66 }
				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#003248',
				tipopcions:'hipotesi_llindar_unitat', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				escenaris:[0,1,2,3,4,5]
			},
			{key:36, name: 'Cost econòmic del consum energètic de calefacció', tipus:'eco', unitat:'nosesap', datatipus:'abs', unitatVisualitzacio:['immoble','sec_censal'],
				opcions:[
					{key:0,		codename:'aa', max_corr:27721.69 , 	med_corr:14403.295 , 	min_corr:1084.9 ,		max:27721.69 , 	 	min:1084.9 },
 					{key:1,		codename:'y',  max_corr:3701.61 , 	med_corr:2159.675 , 	min_corr:617.74 ,		max:3701.61 , 	 	min:617.74 },
 					{key:2,		codename:'z',  max_corr:26.31 , 	med_corr:17.04 , 		min_corr:7.77 ,			max:26.31 , 	 	min:7.77 },
					{key:3,		codename:'ad', max_corr:12404.37 , 	med_corr:6437.135 , 	min_corr:469.9 ,		max:12404.37 , 	 	min:469.9 },
 					{key:4,		codename:'ab', max_corr:1771.62 , 	med_corr:1017.35 ,		min_corr:263.08 ,		max:1771.62 , 		min:263.08 },
 					{key:5,		codename:'ac', max_corr:13.38 , 	med_corr:8.21 , 		min_corr:3.04 ,			max:13.38 ,  		min:3.04 },
					{key:6,		codename:'by', max_corr:27721.69 , 	med_corr:14403.295 , 	min_corr:1084.9 ,		max:27721.69 , 	 	min:1084.9 },
 					{key:7,		codename:'dg',  max_corr:3701.61 , 	med_corr:2159.675 , 	min_corr:617.74 ,		max:3701.61 , 	 	min:617.74 },
 					{key:8,		codename:'di',  max_corr:26.31 , 	med_corr:17.04 , 		min_corr:7.77 ,			max:26.31 , 	 	min:7.77 },
					{key:9,		codename:'cs', max_corr:12404.37 , 	med_corr:6437.135 , 	min_corr:469.9 ,		max:12404.37 , 	 	min:469.9 },
 					{key:10,	codename:'dh', max_corr:1771.62 , 	med_corr:1017.35 ,		min_corr:263.08 ,		max:1771.62 , 		min:263.08 },
 					{key:11,	codename:'dj', max_corr:13.38 , 	med_corr:8.21 , 		min_corr:3.04 ,			max:13.38 ,  		min:3.04 },
					
				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#003248',
				tipopcions: 'consum_hipotesi_unitat',
				opcioSeleccionada:1,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:1,
					consum:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				escenaris:[0,1,2,3,4,5]
			},
			{key:19, name: 'Import de la factura energètica', tipus:'eco',datatipus:'abs',unitat:'€', unitatVisualitzacio:['immoble','sec_censal'],
				opcions: [
					{key:0,		codename:'as', max_corr:71585.58 , 	med_corr:37063.845 , 	min_corr:2542.11 , 		max:71585.58 , 	 	min:2542.11 },
 					{key:1,		codename:'aq', max_corr:5838.9 , 	    med_corr:3877.775 , 	min_corr:617.74 , 	max:5838.9 , 	    min:617.74 },
 					{key:2,		codename:'ar', max_corr:51.89 , 		med_corr:34.845 , 		min_corr:17.8 , 	max:51.89 , 	 	min:17.8 },
					{key:3,		codename:'ay', max_corr:39110.72 , 	med_corr:20229.805 , 	min_corr:1348.89 , 		max:39110.72 , 	 	min:1348.89 },
 					{key:4,		codename:'aw', max_corr:2975.67 , 	med_corr:2016.33 ,		min_corr:1056.99 , 		max:2975.67 , 		min:1056.99 },
 					{key:5,		codename:'ax', max_corr:29.05 , 		med_corr:18.915 , 		min_corr:8.78 , 	max:29.05 , 	 	min:8.78 },
					{key:6,		codename:'av', max_corr:55340.91 , 	med_corr:28603.215 , 	min_corr:1865.52 , 		max:55340.91 , 	 	min:1865.52 },
 					{key:7,	  	codename:'at', max_corr:1555.85 , 	med_corr:2526.77 , 		min_corr:1555.85 , 		max:1555.85 , 	 	min:1555.85 },
 					{key:8,	  	codename:'au', max_corr:38.31 , 		med_corr:25.095 , 		min_corr:11.88 , 	max:38.31 , 	 	min:11.88 },
					{key:9,	  	codename:'bb', max_corr:39718.52 , 	med_corr:20510.28 , 	min_corr:1302.04 , 		max:39718.52 , 	 	min:1302.04 },
 					{key:10,	codename:'az', max_corr:2120.62 , 	med_corr:1627.99 , 		min_corr:1135.36 , 		max:2120.62 , 	 	min:1135.36 },
 					{key:11,	codename:'ba', max_corr:27.73 , 		med_corr:17.545 , 		min_corr:7.36, 		max:27.73 , 	 	min:7.36 }
				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#003248',
				tipopcions:'hipotesi_llindar_unitat', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				escenaris:[0,1,2,3,4,5]
			},
			{key:37, name: 'Import de la factura energètica del consum teòric total', tipus:'eco',datatipus:'abs',unitat:'€', unitatVisualitzacio:['immoble','sec_censal'],
				opcions: [
					{key:0,		codename:'as', max_corr:71585.58 , 	med_corr:37063.845 , 	min_corr:2542.11 , 		max:71585.58 , 	 	min:2542.11 },
 					{key:1,		codename:'av', max_corr:5838.9 , 	    med_corr:3877.775 , 	min_corr:617.74 , 	max:5838.9 , 	    min:617.74 },
 				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#003248',
				tipopcions:'hipotesi', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				escenaris:[0,1,2,3,4,5]
			},
			{key:20, name: 'Renda mínima', tipus:'eco',datatipus:'abs',unitat:'€', unitatVisualitzacio:['immoble','sec_censal'],
				opcions: [
					{key:0,	 codename:'bg',max_corr:715855.77 , 	med_corr:370638.42 , 	min_corr:25421.07, max:715855.77 ,  	min:25421.07 },
 					{key:1,	 codename:'bi',max_corr:553409.07 , 	med_corr:286032.13 , 	min_corr:18655.19, max:553409.07 ,  	min:18655.19 },
 					{key:2,	 codename:'bh',max_corr:391107.19 , 	med_corr:202298.04 , 	min_corr:13488.89, max:391107.19 ,  	min:13488.89 },
					{key:3,	 codename:'bj',max_corr:397185.22 , 	med_corr:205102.815 , 	min_corr:13020.41, max:397185.22 ,  	min:13020.41 },
 					
 					
				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#003248',
				tipopcions:'hipotesi_llindar', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				escenaris:[0,1,2,3,4,5]
			},
			{key:46, name: 'Renda mínima', tipus:'eco',datatipus:'abs',unitat:'€', unitatVisualitzacio:['immoble','sec_censal'],
				opcions: [
					{key:0,	 codename:'bg',max_corr:715855.77 , 	med_corr:370638.42 , 	min_corr:25421.07, max:715855.77 ,  	min:25421.07 },
 					{key:1,	 codename:'bh',max_corr:553409.07 , 	med_corr:286032.13 , 	min_corr:18655.19, max:553409.07 ,  	min:18655.19 },
 				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#003248',
				tipopcions:'hipotesi', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				escenaris:[0,1,2,3,4,5]
			},
			{key:38, name: 'Risc de pobresa energètica', tipus:'eco', unitat:'%', datatipus:'abs', unitatVisualitzacio:['immoble','sec_censal'],
				opcions: [
					{key:0,		codename:'pa', max_corr:71585.58 , 	med_corr:37063.845 , 	min_corr:2542.11 , 		max:71585.58 , 	 	min:2542.11 },
 					{key:1,		codename:'pb', max_corr:5838.9 , 	    med_corr:3877.775 , 	min_corr:617.74 , 	max:5838.9 , 	    min:617.74 },	
				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#003248',
				tipopcions:'hipotesi', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				escenaris:[0,1,2,3,4,5]
			},
			//OPEN
			{key:21, name:'Qualificació energética a Menorca', tipus:'sing', datatipus:'cat',codename:'menorca da', unitatVisualitzacio:['immoble','sec_censal'],
				valores:[
					{fillColor:'#4b7000', codename:'A'},
					{fillColor:'#70a800', codename:'B'},
					{fillColor:'#c8fe00', codename:'C'},
					{fillColor:'#ffe600', codename:'D'},
					{fillColor:'#fca600', codename:'E'},
					{fillColor:'#cf5000', codename:'F'},
					{fillColor:'#992700', codename:'G'},
					{fillColor:'#dadae3', codename:['Sense','null']}
				],escenaris:[0,1,2,3,4,5]
				
			
			},
			{key:22, name:'Superfície mitjana d\'habitatge per immoble a l\'AMB', tipus:'sing', datatipus:'cat',codename:'sup_cat', unitatVisualitzacio:['immoble','sec_censal'],
				valores:[
					{fillColor:'#318BA3', codename:'+250'},
					{fillColor:'#229bb0', codename:'200-250'},
					{fillColor:'#14abbd', codename:'150-200'},
					{fillColor:'#4eab8d', codename:'100-150'},
					{fillColor:'#89aa5e', codename:'80-100'},
					{fillColor:'#c4aa30', codename:'70-80'},
					{fillColor:'#e1aa18', codename:'60-70'},
					{fillColor:'#ffaa00', codename:'50-60'},
					{fillColor:'#ff6000', codename:'40-50'},
					{fillColor:'#dadae3', codename:['Sense','null']}
				],escenaris:[0,1,2,3,4,5]
				
			
			},
			{key:23, name:'Quantitat de pisos turístics propers a l\'immoble a Barcelona', tipus:'sing', datatipus:'cat',codename:'pisos_cat', unitatVisualitzacio:['immoble','sec_censal'],
				valores:[
					{valor:'Més de 50 pisos',				fillColor:'#ff3800', codename:'+50'},
					{valor:'Entre 21 i 50 pisos',			fillColor:'#ff8500', codename:'21-50'},
					{valor:'Entre 6 i 20 pisos',			fillColor:'#c4aa30', codename:'6-20'},
					{valor:'Entre 1 i 5 pisos',				fillColor:'#14abbd', codename:'1-5'},
					{valor:'Cap',							fillColor:'#14abbd', codename:'Cap'},
					{valor:'Sense dades', 					fillColor:'#dadae3', codename:['Sense','null','False']}
				],
				
				extra:{
					codename:'ConteTuris',
					key:230,
					imgname:'punto',
					valores: [
						{img:require('../assets/punto.png'), codename:'True' },
						{img:'', codename:'False' }
					]
				},escenaris:[0,1,2,3,4,5]
			
			},
			{key:24, name:'Quantitat de comerços pròxims a l\'immoble residencial a S.Cugat del Vallès', tipus:'sing', datatipus:'cat',codename:'comerc_cat', unitatVisualitzacio:['immoble','sec_censal'],
				valores:[
					{fillColor:'#c14252', codename:'+50'},
					{fillColor:'#935476', codename:'16-50'},
					{fillColor:'#5b7490', codename:'4-15'},
					{fillColor:'#229bb0', codename:'1-3'},
					{fillColor:'#89AA5E', codename:'Cap'},
					{fillColor:'#dadae3', codename:['Sense','null']}
				],
				extra:{
					codename:'has_comerc',
					key:240,
					imgname:'punto1',
					valores: [
						{img:require('../assets/punto.png'), codename:'SI' },
						{img:'', codename:'NO' }
					]
				},escenaris:[0,1,2,3,4,5]
			
			},

			//NUEVOS
			
			{key:25, name: 'Compacitat', tipus:'arq', unitatVisualitzacio:['immoble','sec_censal'], unitat:'m<sup>3</sup>/m<sup>2</sup>',
        		datatipus:'abs',  codename:'cl', max_corr: '', med_corr: '', min_corr:'', min:'', max: '',
				
				upcolor: '#132a48', midcolor: '#255390', downcolor: '#6e8cb5', 
				escenaris:[0,1,2,3,4,5]
			},
			
			
			
			
			
			{key:31, name: 'Embodied energy invertida en la intervenció', tipus:'ener', unitat:'', unitatVisualitzacio:['immoble','sec_censal'],
				opcions:[
					{key:1,    datatipus: 'abs',codename:'pi', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:2,    datatipus: 'abs',codename:'pj', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#004f0b',
				tipopcions: 'hipotesi',
				opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				escenaris:[1,2,3,4,5]
			},
			{key:32, name: 'Emissions de CO2 invertides en la intervenció', tipus:'ener', unitat:'', datatipus:'abs', unitatVisualitzacio:['immoble','sec_censal'],
				
				opcions:[
					{key:1,    datatipus: 'abs',codename:'pk', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
					{key:2,    datatipus: 'abs',codename:'pl', max_corr:237028.7 , 	med_corr:123152.45 , 	min_corr: 9276.2,	max:237028.7 ,  	min: 9276.2},
				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#004f0b',
				tipopcions: 'hipotesi',
				opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0
				},
				escenaris:[1,2,3,4,5]
				
			},
			
			
			
			{key:35, name: 'Eficàcia energètica en la reducció del consum d’energia primària de calefacció',tipus:'ener', unitat:'', datatipus:'abs', unitatVisualitzacio:['immoble','sec_censal'],
				opcions:[
					{key:0,max_corr:14.55, med_corr:13.56, min_corr:12.57, max:14.55,  min:12.57, codename:'dx'},
					{key:1,max_corr:14.55, med_corr:13.56, min_corr:12.57, max:14.55,  min:12.57, codename:'dy'},
					{key:2,max_corr:14.55, med_corr:13.56, min_corr:12.57, max:14.55,  min:12.57, codename:'df'},
					{key:3,max_corr:14.55, med_corr:13.56, min_corr:12.57, max:14.55,  min:12.57, codename:'dk'},
				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#004f0b',
				tipopcions: 'consum_hipotesi',
				opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				escenaris:[1,2,3,4,5]
				
				
			},
			
			
			
			
			{key:39, name: 'Inversió econòmica de la intervenció', tipus:'eco',datatipus:'abs',unitat:'€', unitatVisualitzacio:['immoble','sec_censal'],
				
				opcions: [
					{key:0,		codename:'pe', max_corr:18500.0 , 	med_corr:17000.0 , 	min_corr:1100 , 		max:18500.0 , 	 	min:1100 },
 					{key:1,		codename:'pf', max_corr:18500.0 , 	    med_corr:17000.0 , 	min_corr:1100 , 	max:18500.0 , 	    min:1100 },	
				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#003248',
				tipopcions:'hipotesi', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				escenaris:[1,2,3,4,5]
				
			},
			{key:40, name: 'Creació de llocs de treball per la intervenció',tipus:'eco',datatipus:'abs',unitat:'llocs de treball', unitatVisualitzacio:['immoble','sec_censal'],
				
				opcions: [
					{key:0,		codename:'pg', max_corr:71585.58 , 	med_corr:37063.845 , 	min_corr:2542.11 , 		max:71585.58 , 	 	min:2542.11 },
 					{key:1,		codename:'ph', max_corr:5838.9 , 	    med_corr:3877.775 , 	min_corr:617.74 , 	max:5838.9 , 	    min:617.74 },	
				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#003248',
				tipopcions:'hipotesi', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				escenaris:[1,2,3,4,5]
				
			},
			{key:41, name: 'Eficàcia econòmica en la reducció del consum d’energia primària de calefacció', tipus:'eco',datatipus:'abs',unitat:'€', unitatVisualitzacio:['immoble','sec_censal'],
				opcions: [
					{key:0,		codename:'cg', max_corr:71585.58 , 	med_corr:37063.845 , 	min_corr:2542.11 , 		max:71585.58 , 	 	min:2542.11 },
 					{key:1,		codename:'ch', max_corr:5838.9 , 	    med_corr:3877.775 , 	min_corr:617.74 , 	max:5838.9 , 	    min:617.74 },
 					{key:2,		codename:'dl', max_corr:51.89 , 		med_corr:34.845 , 		min_corr:17.8 , 	max:51.89 , 	 	min:17.8 },
					{key:3,		codename:'dm', max_corr:39110.72 , 	med_corr:20229.805 , 	min_corr:1348.89 , 		max:39110.72 , 	 	min:1348.89 },
 					
				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#003248',
				tipopcions:'consum_hipotesi', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				escenaris:[1,2,3,4,5]
			},
			{key:42, name: 'Eficàcia econòmica en la reducció de l’import de la factura energètica del consum teòric total', tipus:'eco',datatipus:'abs',unitat:'€', unitatVisualitzacio:['immoble','sec_censal'],
				opcions: [
					{key:0,		codename:'ce', max_corr:71585.58 , 	med_corr:37063.845 , 	min_corr:2542.11 , 		max:71585.58 , 	 	min:2542.11 },
 					{key:1,		codename:'cf', max_corr:5838.9 , 	med_corr:3877.775 , 	min_corr:617.74 , 	max:5838.9 , 	    min:617.74 },
 				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#003248',
				tipopcions:'hipotesi', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				escenaris:[1,2,3,4,5]
			},
			{key:49, name: 'Eficàcia econòmica en la reducció del cost econòmic del consum energetic real de calefaccio', tipus:'eco',datatipus:'abs',unitat:'€', unitatVisualitzacio:['immoble','sec_censal'],
				opcions: [
					{key:0,		codename:'dd', max_corr:71585.58 , 	med_corr:37063.845 , 	min_corr:2542.11 , 		max:71585.58 , 	 	min:2542.11 },
 					{key:1,		codename:'de', max_corr:5838.9 , 	med_corr:3877.775 , 	min_corr:617.74 , 	max:5838.9 , 	    min:617.74 },
 				],
				upcolor: '#640000', midcolor:'#ffe600', downcolor:'#003248',
				tipopcions:'hipotesi', opcioSeleccionada:0,
				indicadorOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				filtreOpcions: {
					valor:0,
					sup:0,
					llindar:0,
					hipotesi:0,
					unitat:0,
					consum:0
				},
				escenaris:[1,2,3,4,5]
			},
			
			
			
			
			

		]