<template>
	<div class="botonesright" :class="[{rightclose: !rightSidebar}, currentApp.tipo]">
		<button v-for="boton in botonesright" v-if="boton.key!=3&&boton.apps.includes(currentApp.tipo)" class="boton"  @click="rightclick(boton.click)"><font-awesome-icon :icon="boton.icono" />
			<transition name="fadein"><span v-if="!$isMobile()" class="tooltip" v-html="textos_lang.botonesright[boton.key][lang]" ></span></transition>
		</button>
		<button class="boton" @click="rightclick('municipi')">
			<img src="../assets/limit.svg" :alt="textos_lang.botonesright[3][lang]">
			<transition name="fadein">
				<span class="tooltip" v-if="!$isMobile()" v-html="textos_lang.botonesright[3][lang]"></span>
			</transition>
		</button>
		<transition name="fadein">
		<div class="modal-mask modalsel modalshare" v-if="modalShare">
      		<div class="modal-wrapper">
      			<div class="modal-container">
        			<div class="closemodal">
          				<button class="boton sub-boton" @click="close()"><font-awesome-icon icon="times" /></button>
        			</div>
        			<div class="copiar">
        				<p v-html="textos_lang.modalShare[0][lang]"></p>
        				<input type="text" :value="urlShare" id="inputurl"/>
        				<button class="boton" @click="copiar()"><font-awesome-icon icon="clone" /><transition name="fadein"><span class="tooltip" >Copiar URL</span></transition></button>
        				<p class="copiada" v-if="copiada">URL copiada!</p>
        			</div>
				</div>
			</div>
		</div>
	</transition>
  	</div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '../store/store'
import estiloClaro from '../assets/stylelight.json'
import estiloOscuro from '../assets/styledark.json'
import Mapa from './Mapa'
export default {

  	name: 'Botonesright',
  	data(){
  		return {
  			estiloClaro: estiloClaro,
  			estiloOscuro: estiloOscuro,
  			modalShare: false,
  			urlShare:'',
  			copiada:false,
  		}
  	},
  	computed:{ 
  		...Vuex.mapState(['botonesright','rightSidebar','vista','textos_lang','lang']),
  		...Vuex.mapGetters(['currentVista','currentApp']),
  	},
	methods: {
		rightclick: function(boton){
			const mapa = this.$parent.$refs.mapa.$children[0].map;
			if(boton=='cambiarfondo') {
				var estilo = store.state.fondo;
				estilo = estilo == 'Light' ? 'Dark' : 'Light';
				function readdLayer() {
					var colorsense;
					if(estilo == 'Light'){
						colorsense = '#dadae3'
					} else if(estilo == 'Dark') {
						colorsense = '#424242'
					}
					store.state.indicadores.forEach(indicador=>{
						if(indicador.valores){
							indicador.valores[indicador.valores.length-1].fillColor = colorsense;
						}
					})
					store.commit('change_defaultColor', estilo)
					store.commit('change_indicador',store.state.indicadorSeleccionado)
					store.commit('change_estiloIndicador', mapa)
					mapa.addLayer(store.state.capaUzeb, store.state.textos);
					mapa.addLayer(store.state.capaFondo,'uzeb');
		        	mapa.setPaintProperty('uzeb','fill-color',store.state.estiloIndicador);
		        	mapa.setPaintProperty('uzeb','fill-outline-color',store.state.outlineColor[estilo]);
		        	mapa.setPaintProperty('fondo','fill-outline-color',store.state.outlineColor[estilo]);
		        	mapa.setPaintProperty('fondo','fill-color',store.state.colorFondo[estilo]);
		        	var vista = store.state.vista
		        	if(vista){
		        		mapa.setFilter('uzeb', store.getters.currentVista.filtro);
		        		mapa.setFilter('fondo', store.getters.currentVista.filtro[1])
		        	} else {
		        		mapa.setFilter('uzeb', store.state.filtros)
		        	
		        	}
		        	
		        	

				}
				if(estilo==='Dark'){
					mapa.setStyle(estiloOscuro);
				} else if (estilo === 'Light') {
					mapa.setStyle(estiloClaro);
				}
				readdLayer()
				//mapa.once('styledata', function(e){readdLayer()});

			} else if(boton=='mostrartexto'){
				var layers = mapa.getStyle().layers;
				for (var i = 0; i < layers.length; i++) {
					if (layers[i].type === 'symbol') {
						var capa = layers[i].id;
						var visibility = mapa.getLayoutProperty(capa, 'visibility');
						var opcion;
						if(visibility=='undefined'||visibility=='none'){
							opcion="mostrar";
						} else {
							opcion="ocultar"
						}
						store.dispatch('mostrarOcultar', {mapa,capa,opcion})
					}
				}

				
			}	else if(boton=='compartir'){
				this.modalShare = true;
				var base = window.location.origin + '/'+this.lang;
				console.log(mapa.getCenter())
				var bounds = mapa.getBounds();
				var indicador = store.state.indicadorSeleccionado;
				var pitch = mapa.getPitch();
				var bearing = mapa.getBearing();
				var zoom = mapa.getZoom()
				this.urlShare = `${base}?i=${indicador}&b=${bounds._sw.lng.toFixed(6)}&b=${bounds._sw.lat.toFixed(6)}&b=${bounds._ne.lng.toFixed(6)}&b=${bounds._ne.lat.toFixed(6)}&pi=${pitch.toFixed(3)}&be=${bearing.toFixed(3)}&z=${zoom}`;
			} else if(boton=='municipi'){
				var layermunis = mapa.getLayer('municipisline')
				if(typeof layermunis !== 'undefined'){
					var capa = 'municipisline';
					var visibility = mapa.getLayoutProperty(capa, 'visibility');
					var opcion;
					if(visibility=='undefined'||visibility=='none'){
						opcion="mostrar";
					} else {
						opcion="ocultar"
					}
					store.dispatch('mostrarOcultar',{mapa,capa,opcion});
				} else {
					store.state.capaMunicipisLine.source.url = this.currentApp.urlMunis;
					mapa.addLayer(store.state.capaMunicipisLine, store.state.textos);
					console.log('3',store.state.capaMunicipisLine)
				} 

				
				//console.log(mapa)
			}
		},
		close(){
			this.modalShare = false;
			this.copiada = false;
		},
		copiar() {
			var copyText = document.querySelector("#inputurl");
			console.log(copyText, copyText.value)
  			copyText.select();
  			//copyText.setSelectionRange(0, 99999)
			/* Copy the text inside the text field */
			document.execCommand("copy");
			/* Alert the copied text */
  			this.copiada = true;

}
	}
}
</script>

<style lang="css">

</style>