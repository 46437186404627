<template>
  <div id="app">
    <Loading v-if="loading"/>
    <Intro v-if="currentApp.tipo=='simple'"/>
    <Login v-if="(currentApp.tipo=='pro'||currentApp.tipo=='show'||currentApp.tipo == 'arquitectonico')&&!user"/>
    <div class="nav" :class="currentApp.tipo">
        <div class="logo">
          <a href="http://urbanzeb.com" target="_blank"><img :src="require('./assets/'+currentApp.logo)" alt="urbanZEB"></a>
        </div>
        <Menu v-if="currentApp.tipo=='pro'"/>
    </div>
    <!-- <transition name="fade">
      <router-view/>
    </transition> -->
    <div class="vistamapa" :class="currentApp.tipo">
      <LandingHeader v-if="currentApp.tipo==='open'||currentApp.tipo==='demo'"/>
      <Mapa ref="mapa" v-if="((currentApp.tipo=='pro'||currentApp.tipo=='show'||currentApp.tipo=='arquitectonico')&&user)||(currentApp.tipo=='simple')||currentApp.tipo=='open'"/>
       <Botones/>
       <Leftbar/>
       <Tabla v-if="currentApp.tipo!='open'"/>
       <Botonesright/>
       <Topright/>
       <Rightbar v-if="currentApp.tipo!='open'"/>
       <Botonesbottom v-if="currentApp.tipo=='open'"/>
    </div>
  </div>
</template>

<script>
  import Menu from './components/Menu'
  import VistaMapa from './components/VistaMapa'
  import Mapa from './components/Mapa'
  import Botones from './components/Botones'
  import Leftbar from './components/Leftbar'
  import Rightbar from './components/Rightbar'
  import Botonesright from './components/Botonesright'
  import Topright from './components/Topright'
  import Tabla from './components/Tabla'
  import Intro from './components/Intro'
  import Login from './components/Login'
  import Loading from './components/Loading'
  import Botonesbottom from './components/Botonesbottom'
  import LandingHeader from './components/LandingHeaderComponent/LandingHeader'
  import Vuex from 'vuex'
  import axios from 'axios'
  import Vueaxios from 'vue-axios'
  
  export default {
    name: 'App',
    components: {
      Menu, Mapa, Botones, Leftbar, Botonesright, Rightbar, Topright, Tabla, Intro, Login, Loading, VistaMapa, Botonesbottom, LandingHeader
    },
    created(){
      this.$store.commit('set_aplicacion', 5);
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
          this.$store.commit('toggle_Sidebar', 'rightSidebar');
          this.$store.state.movil=true
      };
     
      this.$store.commit('set_minmax')
      if(this.$route.params.lang) {
          this.$store.state.lang = this.$route.params.lang
        }
      
     
    },
    mounted(){
      //movil pero no open
       if((window.innerWidth<=500||/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))&&this.$store.getters.currentApp.tipo!='open'){
          document.querySelector('.mapboxgl-ctrl-bottom-right').classList.add('rightclose');
          if(document.querySelector('.tabla-section')){document.querySelector('.tabla-section').style.display='none';}
          document.querySelector('#map').style.height='calc(100vh - 60px)'
          document.querySelector('.botonesright').style.bottom='148px'
          document.querySelectorAll('.tooltip').forEach(el=> el.style.display = 'none')
          document.querySelectorAll('.boton').forEach(el=> el.style.fontSize = '14px')
          document.querySelectorAll('.sub-boton').forEach(el=> {el.style.width = '25px';el.style.height= '25px'});
        
        }
        //movil y open
        if((window.innerWidth<=500||this.$isMobile())&&this.$store.getters.currentApp.tipo=='open'){
          document.querySelector('.botonesright').style.bottom='148px'
          document.querySelectorAll('.tooltip').forEach(el=> el.style.display = 'none')
          document.querySelectorAll('.boton').forEach(el=> el.style.fontSize = '14px')
          document.querySelectorAll('.sub-boton').forEach(el=> {el.style.width = '25px';el.style.height= '25px'});
          document.querySelector('.vistamapa').style.gridTemplateColumns='250px auto auto';
        }
        
        //recalculo las rows de la tabla y la tabla cuando se cambia el tamaño de la pantalla
        var height = window.innerHeight
        var doit;
        window.onresize = function() {
          clearTimeout(doit);
          doit = setTimeout(function() {
              if(document.querySelector('.tabla-section').style.transform == 'translateY(0%)'&&window.innerHeight!=height){
                if(document.querySelector('.tabla-section').style.height == '50%'){
                  this.$store.dispatch('updateTableRows','med').then(this.$store.state.tableLoading=false)
                } else if(document.querySelector('.tabla-section').style.height == 'calc(100% - 60px)'){
                  this.$store.dispatch('updateTableRows','up').then(this.$store.state.tableLoading=false)
                };

              };
              if(this.$store.state.mapelement){
                this.$store.state.mapelement.resize();
              }
          }, 500);
        }

    },
    computed:{
      ...Vuex.mapState(['user','loading','lang']),
      ...Vuex.mapGetters(['currentApp']),
    },
        
    
  
  }
</script>

<style lang="scss">
@import './assets/style.scss';

</style>

