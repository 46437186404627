<template>
	<div class="botonesbottom">
		<div class="boton" @click="info()">
			<font-awesome-icon icon="info" />
			<transition name="fadein"><span class="tooltip" v-if="!$isMobile()" v-html="textos_lang.botonesbottom.botones[0][lang]"></span></transition>
		</div>
		<div class="boton" @click="credits()">
			<img src="../assets/cicl.png">
			<transition name="fadein"><span class="tooltip" v-if="!$isMobile()" v-html="textos_lang.botonesbottom.botones[1][lang]"></span></transition>
		</div>
		<div class="boton" @click="contacte()">
			<font-awesome-icon icon="question" />
			<transition name="fadein"><span class="tooltip" v-if="!$isMobile()" v-html="textos_lang.botonesbottom.botones[2][lang]"></span></transition>
		</div>
		<!-- <router-link class="boton" to="/es" v-if="lang=='ca'">
			<span>{{textos_lang.botonesbottom.botones[3][lang]}}</span>
			<transition name="fadein"><span class="tooltip" v-if="!$isMobile()" v-html="textos_lang.botonesbottom.botones[4][lang]"></span></transition>
		</router-link>
		<router-link class="boton" to="/ca" v-if="lang=='es'">
			<span>{{textos_lang.botonesbottom.botones[3][lang]}}</span>
			<transition name="fadein"><span class="tooltip" v-if="!$isMobile()" v-html="textos_lang.botonesbottom.botones[4][lang]"></span></transition>
		</router-link> -->
		<transition name="fadein">
		<div class="modal-mask modalinfo" v-if="modalinfo">
      		<div class="modal-wrapper">
      			<div class="modal-container">
        			<div class="closemodal">
          				<button class="boton" @click="close()"><font-awesome-icon icon="times" /></button>
        			</div>
	        		<div class="modal-body">
		        		<div class="infouz">
		          			<p v-html="textos_lang.botonesbottom.que[0][lang]"></p>
		          			<p v-html="textos_lang.botonesbottom.que[1][lang]"></p>
		        
		          			<div class="logosinfo">
				              <div class="logo">
				                <a href="http://urbanzeb.com" target="_blank"><img class="urbanzeb" src = "../assets/urbanzeb.svg"></a>
				              </div>
				              <div class="logo">
				                <a href="http://ciclica.eu" target="_blank"><img class="ciclica" src = "../assets/ciclica.png"></a>
				              </div>
				              <div class="logo">
				                <a href="https://www.openstreetmap.org" target="_blank"><img class="osmi" src = "../assets/Openstreetmap.png"></a>
				              </div>
				              <div class="logo">
				                <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank"><img class="cc" src = "../assets/cc.png"></a>
				              </div>
		            		</div>
		          		</div>
		        	</div>
		      	</div>
			</div>
			
		</div>
		</transition>
		<transition name="fadein">
			<div class="modal-mask modalinfo" v-if="modalcredits">
	      		<div class="modal-wrapper">
	      			<div class="modal-container">
	        			<div class="closemodal">
	          				<button class="boton" @click="close()"><font-awesome-icon icon="times" /></button>
	        			</div>
		        		<div class="modal-body">
			        		<div class="credits">
			        			<h4 v-html="textos_lang.botonesbottom.credits[0][lang]"></h4>
			        			<a href="http://ciclica.eu" target="_blank" title="Cíclica[space·comunity·ecology]">
			        				<img src="../assets/ciclica.png" alt="Cíclica[space·comunity·ecology]">
			        			</a>
			        			<h4 v-html="textos_lang.botonesbottom.credits[1][lang]"></h4>
			        			<a href="https://etsav.upc.edu/" title="Escola Tècnica Superior d'Arquitectura del Vallès" target="_blank">
			        				<img src="../assets/etsav.png">
			        			</a>
			        			<h4 v-html="textos_lang.botonesbottom.credits[2][lang]"></h4>
			        			<div class="suports">
			        				<a v-for="suport in suports" :href="suport.link" target="_blank" title="suport.title">
			        					<img :src="suport.src">
			        				</a>
			        			</div>
			        		</div>
			        	</div>
			      	</div>
				</div>
			</div>
		</transition>
		<transition name="fadein">
		<div class="modal-mask modalinfo modalcontact" v-if="modalcontact">
      		<div class="modal-wrapper">
      			<div class="modal-container">
        			<div class="closemodal">
          				<button class="boton" @click="close()"><font-awesome-icon icon="times" /></button>
        			</div>
	        		<div class="modal-body">
	        			<h3 v-if="enviado" v-html="textos_lang.botonesbottom.contacto.enviado[lang]"></h3>
		        		<div class="formulari" v-if="!enviado">
				            <h3 v-html="textos_lang.botonesbottom.contacto.titulo[lang]"></h3>
				            <div class="message-contactform"></div>
				            <form id="contactform">
				              <input type="text" name="nom" :placeholder="textos_lang.botonesbottom.contacto.form.nom[lang]"  v-model="contactform.nom" required>
				              <input  type="email" name="email" :placeholder="textos_lang.botonesbottom.contacto.form.email[lang]" v-model="contactform.email" required>
				              <input type="text" name="assumpte" :placeholder="textos_lang.botonesbottom.contacto.form.assumpte[lang]" v-model="contactform.title" required>
				              <textarea  rows="4" cols="50" name="comment" :placeholder="textos_lang.botonesbottom.contacto.form.comment[lang]" v-model="contactform.message" required></textarea>
				             <div class="politica"> 
				             	<input type="checkbox" name="acepto" id="acepto" required> 
				             	<div class="checkbox-input"><font-awesome-icon icon="circle" /></div>
				             	
				             	<label><span v-html="textos_lang.botonesbottom.contacto.politica[0][lang]"></span><a target="_blank" href="http://urbanzeb.com/privacidad" v-html="textos_lang.botonesbottom.contacto.politica[1][lang]"> </a></label>

				             </div>
				            </form>
				            <div class="botonform">
				                <button class="boton" type="submit" form="contactform" @click.prevent="sendcontactform()">
				                	<font-awesome-icon icon="paper-plane" />
				                	<transition name="fadein"><span class="tooltip" v-html="textos_lang.botonesbottom.contacto.enviar[lang]"></span></transition></button>
				              </div>
				            
				          </div>

				      </div>
		        	</div>
		      	</div>
			</div>
		</transition>
	</div>
</template>

<script>
import Vue from "vue";
import Axios from 'axios'
import VueAxios from 'vue-axios'
import Vuex from 'vuex'
export default {

  name: 'Botonesbottom',

  data () {
    return {
    	modalinfo:false,
    	modalcredits:false,
    	modalcontact:false,
    	suports:[
    		{title:'Programa aracoop', link:'http://aracoop.coop/', src:require('../assets/aracoop.png')},
    		{title:'Departament de treball, afers socials i families', link:'https://treballiaferssocials.gencat.cat/ca/inici', src:require('../assets/gencat.png')},
    		{title:'Ministerio de trabajo, migraciones y seguridad social', link:'http://www.mitramiss.gob.es/',src:require('../assets/trabajo.png')}
    	],
    	contactform: {
    		nom:'',
    		email:'',
    		title:'',
    		message:''
    	},
    	enviado:false,
    }
  },
  watch: {
    '$route' (to, from) {
      this.lang = this.$route.params.lang
    }
  },
  computed: {
  	...Vuex.mapState(['textos_lang', 'lang']),
  },
  methods:{
  	info(){
  		this.modalinfo=true;
  	},
  	credits(){
  		this.modalcredits=true;
  	},
  	contacte(){
  		this.modalcontact = true;
  	},
  	close(){
  		this.modalinfo = false;
  		this.modalcredits = false;
  		this.modalcontact = false;
  		this.enviado = false;
  	},
  	sendcontactform(){
  		var qs = require('qs');
  		Axios({
          method: 'POST',
          url: `https://ciclica.eu/send-contact.php`,
          data:qs.stringify({ 
          	'contact_name': this.contactform.nom,
          	'contact_email': this.contactform.email,
          	'contact_title': this.contactform.title,
          	'contact_message': this.contactform.message,
          }),
          headers:{}
        })
        .then(response => {
        	this.contactform= {
        		nom:'',
        		email:'',
        		title:'',
        		message:''
        	}
        	this.enviado = true;
        })
     
        
        .catch(response => console.log('submit error: '+ response))
        var _this = this;
        setTimeout(function(){
        	_this.enviado = false;
        },6000)
  	},
  	idioma(){
  		if(store.state.lang=='ca'){
  			store.state.lang = 'es';
  			this.$route.params.lang='es'
  		} else {
  			store.state.lang = 'ca'
  		}
  	}
  }
}
</script>

<style lang="css" scoped>
</style>