<template>
	<transition name="fadein">
			<div class="modal-mask modalsel">
      			<div class="modal-wrapper">

        			<div class="modal-container">
        				<div class="closemodal"><button class="boton sub-boton" @click="remove()"><font-awesome-icon icon="times" /></button></div>
						<div class="formu-seleccio">
							<button class="botontexto" @click="acrear()" v-html="textos_lang.crearSeleccio.botones[0][lang]"><span class="boton"><font-awesome-icon icon="folder-plus" /></span></button>
							<hr>
							<button class="botontexto" @click="aguardar()"v-html="textos_lang.crearSeleccio.botones[1][lang]" ><span class="boton"><font-awesome-icon icon="folder-open" /></span></button>
              <template v-if="vista">
                <hr>
                <button class="botontexto" @click="aeliminar()" v-html="textos_lang.crearSeleccio.botones[2][lang]"><span class="boton"><font-awesome-icon icon="folder-minus" /></span></button>
              </template>
						</div>
						<div class="crearsel selformcont" v-if="crear">
							<h4 v-html="textos_lang.crearSeleccio.crear.tit[lang]"></h4>
							<form>
                <div class="alerta" v-if="alerta" v-html="alerta"></div>
								<input name="namesel" type="text" :placeholder="textos_lang.crearSeleccio.crear.form.nom[lang]" required>
								<textarea name="dessel" :placeholder="textos_lang.crearSeleccio.crear.form.descripcio[lang]"></textarea>
								<button type="submit" @click.prevent="desarNovaSel(seleccions.length)" class="botontexto">{{textos_lang.crearSeleccio.crear.form.desar[lang]}} <span class="boton"><font-awesome-icon icon="save" /></span></button>
							</form>
						</div>
						<div class="guardarinsel selformcont" v-if="guardar">
							<div v-if="seleccions.length>0">
                <h4 v-html="textos_lang.crearSeleccio.guardar[0][lang]"</h4>
                <ul>
                <li v-for="sel in seleccions" v-if="!(currentApp.key==6&&seleccions.indexOf(sel)==0)" @click="afegiraSel(sel.id_sel)" v-html="sel.nom_sel"></li>
                </ul>       
              </div>
							<div v-else v-html="textos_lang.crearSeleccio.guardar[1][lang]"></div>
						</div>
            <div class="guardarinsel selformcont" v-if="eliminar">
              <h4 v-html="textos_lang.crearSeleccio.eliminar[0][lang]"></h4>
              <div class="sino">
                <button class="botontexto" @click="remove()">{{textos_lang.crearSeleccio.eliminar[1][lang]}} <span class="boton"><font-awesome-icon icon="times"/></span></button>
                <button class="botontexto" @click="redoSel(vista)">{{textos_lang.crearSeleccio.eliminar[2][lang]}} <span class="boton"><font-awesome-icon icon="arrow-right"/></span></button>
              </div>
            </div>
					</div>
				</div>
			</div>
	</transition>
</template>

<script>
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import Vueaxios from 'vue-axios'
import { store } from '../store/store'
export default Vue.extend({ 

  name: 'Crearseleccio',

  data () {
    return {
    	crear:false,
    	guardar:false,
      alerta:false,
      eliminar:false,
    }
  },
  created(){
    
  },
  computed:{
  	...Vuex.mapState(['seleccions', 'user', 'vista','textos_lang','lang']),
    ...Vuex.mapGetters(['currentApp'])
  },
  methods:{
  	remove(){
  		document.querySelector('.modalsel').remove()
  	},
  	acrear(){
  		if(!this.crear){
  			this.crear = true;
  			if(this.guardar){
  				this.guardar = false;
  			}
        if(this.eliminar){
          this.eliminar = false;
        }
  		}
  	},
  	aguardar(){
  		if(!this.guardar){
  			this.guardar = true
  			if(this.crear){
  				this.crear = false
  			}
        if(this.eliminar){
          this.eliminar = false;
        }
  		}
  	},
    aeliminar(){
      if(!this.eliminar){
        this.eliminar = true
        if(this.crear){
          this.crear = false
        }
        if(this.guardar){
          this.guardar = false;
        }
      }
    },
  	desarNovaSel(key){
      store.state.loading = true;
  		var nom = document.querySelector('input[name="namesel"]').value;
  		var descripcio = document.querySelector('textarea[name="dessel"]').value;
      var existe = false;
      if(this.seleccions.length>0){
        this.seleccions.forEach(sel=>{
          if(sel.nom_sel==nom){
            existe = true
          }
        })
      }  
      var filtros = []
      if(store.state.vista){
        var fils = ['all',store.getters.currentFiltros[1]]
        for(var x=2; x<store.getters.currentFiltros.length;x++){
          fils[1][1].push(store.getters.currentFiltros[x])
        } 
        filtros.push(...fils)
      } else {
        filtros.push('all',['any',[...store.state.filtros]])
      }
      var definicio = this.definicio(filtros[1])
      //creación selección
      if(nom.length>0&&!existe){
        var data = {
          'nom_sel': nom,
          'desc_sel': descripcio,
          'filtro': filtros,
          'definicio':definicio
        }
        var csrftoken = store.dispatch('getCookie', 'csrftoken')
        console.log('DATASEL')
        axios.post(`api/create_selection/`, data, {headers:{'X-CSRFToken':csrftoken}})
        .then(response => {
          if(response.data.seleccion_object){
            store.state.seleccions.push(response.data.seleccion_object);
            this.alerta = false;
            this.remove();
            store.state.loading = false;
          } else {
            store.state.errorloading = true;
            setTimeout(function() {
              store.state.loading = false;
              store.state.errorloading = false
                   
            }, 3000)
          }
          
        })
        .catch(err => {
          console.log(err);
          store.state.calculant = false
          store.state.errorloading = true;
          setTimeout(function() {
            store.state.loading = false;
            store.state.errorloading = false
             
          }, 3000)});
      } else {
        if(existe) {
          this.alerta = this.textos_lang.crearSeleccio.crear.form.alerta1[this.lang];
        } else {
          this.alerta = this.textos_lang.crearSeleccio.crear.form.alerta2[this.lang];
        }
      }
  	},
    afegiraSel(key){
      store.state.loading=true;
      var seleccio = store.state.seleccions.find(sel=>sel.id_sel==key);
      if(!this.vista) {
        var fils = ['all']
        for(var f=1; f<store.state.filtros.length; f++){
          fils.push(store.state.filtros[f])
        }
        seleccio.filtro[1].push(fils)
      } else {
        var currentFiltros= store.getters.currentFiltros;
        var fils = ['all']
        currentFiltros[1][1].forEach(arr=>{
          if(currentFiltros[1][1].indexOf(arr)>0){
            fils.push(arr)
          }
        })
        currentFiltros.forEach(arr=>{
          if(currentFiltros.indexOf(arr)>1){
            fils.push(arr)
          }
        })
        seleccio.filtro[1].push(fils)
       
      }
      var definicio = this.definicio(seleccio.filtro[1])
      //actualizo la selección
      var data = {
        'id_sel': key,
        'new_filter': seleccio.filtro,
        'filtro_ver': seleccio.filtro_ver,
        'new_definicio': definicio
      }
      var csrftoken = store.dispatch('getCookie', 'csrftoken')
      axios.post(`api/update_filter/`, data, {headers:{'X-CSRFToken':csrftoken}})
      .then(response => {
        var ind = store.state.seleccions.indexOf(store.state.seleccions.find(sel=>sel.id_sel==key))
        Vue.set(store.state.seleccions, ind, response.data.seleccion_object)
        store.state.loading = false;
      })
      .catch(err => {console.log(err);});
    
      this.remove();
    },
    redoSel(key){
      console.log('redo')
      //mostramos 'carregant'
      store.state.loading = true;
      var definicio = []
     
        //cojo el objeto selección
        var seleccio = store.state.seleccions.find(sel=>sel.id_sel==key);
        for(var a=2;a<seleccio.filtro.length;a++){
          for(var b=1;b<seleccio.filtro[1].length;b++){
            seleccio.filtro[1][b].push(seleccio.filtro[a])
          }
        };
        seleccio.filtro = seleccio.filtro.slice(0,2)
      
        var definicio = this.definicio(seleccio.filtro[1])
        var data = {
          'id_sel': key,
          'new_filter': seleccio.filtro,
          'filtro_ver': seleccio.filtro_ver,
          'new_definicio': definicio
        }
        var csrftoken = store.dispatch('getCookie', 'csrftoken')
        axios.post(`api/update_filter/`, data, {headers:{'X-CSRFToken':csrftoken}})
        .then(response => {
          var ind = store.state.seleccions.indexOf(store.state.seleccions.find(sel=>sel.id_sel==key))
          Vue.set(store.state.seleccions, ind, response.data.seleccion_object)
          store.state.seleccions[ind].lista = [];
          
        })
        .then(response => {
          store.dispatch('updateAmbit', {key})
        })
        .catch(err => {console.log(err);});
      
      this.remove()
    
      
    },
    definicio(filtros){
      var definicio = []
      function getValors(tipus, indicador,fil,condicion){
        //si es categorico, guardo los valores
        if(tipus=='in'){
          var elem = condicion.filtros.find(def=>def.key==indicador.key)
          if(elem){
            var suind= condicion.filtros.indexOf(elem)
            condicion.filtros[suind].valors=[]
            for(var x=2;x<fil.length;x++){
              indicador.valores.forEach(valor=>{
                if(!condicion.filtros[suind].valors.includes(indicador.valores.indexOf(valor))){
                    if(valor.codename==fil[x]){
                      condicion.filtros[suind].valors.push(indicador.valores.indexOf(valor))
                    }
                    if(typeof valor.codename == 'object'&&valor.codename.includes(fil[x])){
                      condicion.filtros[suind].valors.push(indicador.valores.indexOf(valor)) 
                    }
                  }
              })
            }
          }  
        } else if(tipus=='<='){
          //si es absoluto, guardo el valor máximo
          var elem2 = condicion.filtros.find(def=>def.key==indicador.key)
          if(elem2){
            var suind= condicion.filtros.indexOf(elem2)
            if(!condicion.filtros[suind].rango){
              condicion.filtros[suind].rango = {}
            }
            condicion.filtros[suind].rango.max=fil[2]
          }  
        } else if(tipus=='>='){
          //si es absoluto, guardo el valor mínimo
          var elem3 = condicion.filtros.find(def=>def.key==indicador.key)
          if(elem3){
            var suind= condicion.filtros.indexOf(elem3)
            if(!condicion.filtros[suind].rango){
              condicion.filtros[suind].rango = {}
            }
            condicion.filtros[suind].rango.min=fil[2]
          }  
        }
      }

      filtros.forEach((all,index)=>{
        if (index < 1) return;
        var condicion = {'key':index,'filtros':[]}
        var codigos = [];
        all.forEach((fil,index)=>{
          if (index < 1) return;
          if(fil[1]=='ej'){
            condicion.filtros.push({'name':'area'})
          } 
          if(fil[1]=='fa'){
            var valors = [];
            for(var x=2;x<fil.length;x++){
              this.currentApp.municipis.forEach(muni=>{
                if(muni.code==fil[x]){
                    valors.push(muni.nom)
                  }
              })
            }
            condicion.filtros.push({'name':'munis','valors':valors})
          } 
          if(fil[1]=='er'){
            var valors = [];
            for(var x=2;x<fil.length;x++){
              
                    valors.push(fil[x])
                  
              
            }
            condicion.filtros.push({'name':'seccs','valors':valors})
          } 
          store.state.indicadores.forEach(indicador=>{
            if(store.getters.currentApp.indicadores.tots.includes(indicador.key)){
              if(!indicador.opcions){
                if(indicador.codename==fil[1]){
                  if(fil[0]!='<='){
                    condicion.filtros.push({'key':indicador.key})  
                    getValors(fil[0],indicador, fil,condicion)
                  } else {
                    getValors(fil[0],indicador, fil,condicion)
                  }
                  
                }
              } else {
                
                indicador.opcions.forEach(opcio=>{
                  if(opcio.codename==fil[1]){
                    if(fil[0]!='<='){
                      condicion.filtros.push({'key':indicador.key})
                      getValors(fil[0],indicador, fil,condicion)
                    } else {
                      getValors(fil[0],indicador, fil,condicion)
                    }
                    
                  }
                  
                })
              };
            }
          })
          
        })
        definicio.push(condicion)
      })
      return definicio
    }
    
  }
})
</script>

<style lang="css" scoped>
</style>