import * as ambLeyenda from './amb_leyenda.json'
import * as menorcaLeyenda from './menorca_leyendas.json'
import * as molletLeyenda from './Mollet_leyenda.json'
import * as rubiLeyenda from './rubi_leyenda.json'
export const aplicaciones = [
			{ 	key:0, 
				url:'https://tiles.urbanzeb.com/VT_singulars/data/amb_ind',
				urlFondo:'https://tiles.urbanzeb.com/VT_AMB/data/SC.json',
				tipo:'pro',
				numEscenarios:2,
				mapAttributes:{
					zoom:10,
					lng:2.110511,
					lat:41.409882,
					center:[2.110511,41.409882]
				}, titulo:'AMB',
				municipis:[
					{key:1,  code:'2',   nom:'Badalona'},
					{key:2,  code:'3',   nom:'Badia del Vallès'},
					{key:3,  code:'4',   nom:'Barberà del Vallès'},
					{key:4,  code:'5',   nom:'Castelldefels'},
					{key:5,  code:'6',   nom:'Cerdanyola del Vallès'},
					{key:6,  code:'7',   nom:'Cornellà de Llobregat'},
					{key:7,  code:'8',   nom:'Esplugues de Llobregat'},
					{key:8,  code:'9',   nom:'Gavà'},
					{key:9,  code:'10',  nom:'L\'Hospitalet de Llobregat'},
					{key:10, code:'11', nom:'Molins de Rei'},
					{key:11, code:'12', nom:'Montcada i Reixac'},
					{key:12, code:'13', nom:'El Prat de Llobregat'},
					{key:13, code:'14', nom:'Ripollet'},
					{key:14, code:'15', nom:'Santa Coloma de Gramenet'},
					{key:15, code:'16', nom:'Sant Adrià de Besòs'},
					{key:16, code:'17', nom:'Sant Andreu de la Barca'},
					{key:17, code:'18', nom:'Sant Boi de Llobregat'},
					{key:18, code:'19', nom:'Sant Cugat del Vallès'},
					{key:19, code:'20', nom:'Sant Feliu de Llobregat'},
					{key:20, code:'21', nom:'Sant Joan Despí'},
					{key:21, code:'22', nom:'Sant Vicenç dels Horts'},
					{key:22, code:'23', nom:'Viladecans'},
				],
				minmaxFile: ambLeyenda,
				indicadores:{
		        	arq:[0,1,2,3,4,5,6,7,8,9],
		        	ener:[10,11,12,13,14,15,16,17],
		        	eco:[18,19,20],
		        	tots:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
				},
				logo: 'urbanzeb.svg',
			},
			{ 	key:1, 
				url:'https://tiles.urbanzeb.com/VT_men_Test/data/full', 
				urlFondo:'https://tiles.urbanzeb.com/VT_men_Test/data/geom.json',
				tipo:'pro',
				numEscenarios:2,
				mapAttributes:{
					zoom:10,
					lng:4.1121,
					lat:39.957,
					center:[4.1121,39.957]
				}, titulo:'Menorca',
				municipis:[
		        	{key:0, code:'002', nom:'Alaior'},
		        	{key:1, code:'015', nom:'Ciutadella de Menorca'},
		        	{key:2, code:'023', nom:'Ferreries'},
		        	{key:3, code:'032', nom:'Maó/Mahón'},
		        	{key:4, code:'037', nom:'Es Mercadal'},
		        	{key:5, code:'052', nom:'Sant Lluis'},
		        	{key:6, code:'064', nom:'Es Castell'},
		        	{key:7, code:'902', nom:'Es Migjorn Gran'},
		        ],
		        minmaxFile: menorcaLeyenda,
			},
			{ 	key:2, 
				url:'https://tiles.urbanzeb.com/VT_mollet/data/arquitect.json', 
			    tipo:'simple',
			    numEscenarios:2,
				mapAttributes:{
					zoom:13,
					lng:2.225,
					lat:41.540,
					center:[2.225,41.540]
				}, titulo:'Mollet del Vallès',
				municipis:[],
				minmaxFile: molletLeyenda,
				logo: 'urbanzeb.svg',
			},
			{ 	key:3, 
				url:'https://tiles.urbanzeb.com/VT_AMB/data/full.json', 
			    tipo:'show',
			    numEscenarios:2,
				mapAttributes:{
					zoom:10,
					lng:2.110511,
					lat:41.409882,
					center:[2.110511,41.409882]
				}, titulo:'AMB',
				municipis:[],
				minmaxFile: ambLeyenda,
				indicadores:{
		        	arq:[0,1,2,3,4,5,6,7,8,9],
		        	tots:[0,1,2,3,4,5,6,7,8,9]
				},
				logo: 'urbanzeb.svg',
			},
			{ 	key:4, 
				url:'https://tiles.urbanzeb.com/VT_menorca_arq/data/full', 
				urlFondo:'https://tiles.urbanzeb.com/VT_menorca_arq/data/geom.json',
				tipo:'arquitectonico',
				numEscenarios:2,
				mapAttributes:{
					zoom:10,
					lng:4.1121,
					lat:39.957,
					center:[4.110091,39.971226]
				}, titulo:'Menorca',
				municipis:[
		        	{key:0, code:'002', nom:'Alaior'},
		        	{key:1, code:'015', nom:'Ciutadella de Menorca'},
		        	{key:2, code:'023', nom:'Ferreries'},
		        	{key:3, code:'032', nom:'Maó/Mahón'},
		        	{key:4, code:'037', nom:'Es Mercadal'},
		        	{key:5, code:'052', nom:'Sant Lluis'},
		        	{key:6, code:'064', nom:'Es Castell'},
		        	{key:7, code:'902', nom:'Es Migjorn Gran'},
		        ],
		        minmaxFile: menorcaLeyenda,
		        indicadores:{
		        	arq:[0,1,2,3,4,5,6,7,8,9],
		        	tots:[0,1,2,3,4,5,6,7,8,9]
				},
				logo:'urbanzeb.svg'
			},
			{ 	key:5, 
				url:'https://tiles.urbanzeb.com/VT_singulars/data/amb_ind', 
				urlFondo:'https://tiles.urbanzeb.com/VT_singulars/data/amb_geom.json',
				urlMunis:'https://tiles.urbanzeb.com/Bases/data/munis.json',
				tipo:'open',
				numEscenarios:2,
				mapAttributes:{
					zoom:10,
					lng:4.1121,
					lat:39.957,
					center:[2.110511,41.409882],
					bounds:[1.886902,41.263356,2.296143,41.574361]
				}, titulo:'Open',
				municipis:[
		        	{key:0, code:'002', nom:'Alaior'},
		        	{key:1, code:'015', nom:'Ciutadella de Menorca'},
		        	{key:2, code:'023', nom:'Ferreries'},
		        	{key:3, code:'032', nom:'Maó/Mahón'},
		        	{key:4, code:'037', nom:'Es Mercadal'},
		        	{key:5, code:'052', nom:'Sant Lluis'},
		        	{key:6, code:'064', nom:'Es Castell'},
		        	{key:7, code:'902', nom:'Es Migjorn Gran'},
		        ],
		        minmaxFile: ambLeyenda,
		        indicadores:{
		        	gen:[0,3,4,5],
		        	sing:[21,22,23,24],
		        	tots:[0,3,4,5,21,22,23,24]
				},
				logo: 'urbanzebopen.svg',
				mapas:{
					indicadors:{
						
						21: 
							{
								url:'https://tiles.urbanzeb.com/VT_singulars/data/singulars.json',
								bounds:[3.682997,39.801309,4.537185,40.140722],
								layer:'qual_men'
							},
						22: {
								url:'https://tiles.urbanzeb.com/VT_singulars/data/singulars.json',
								bounds:[1.886902,41.263356,2.296143,41.574361],
								layer:'sup_amb'
						},
						23: {
								url:'https://tiles.urbanzeb.com/VT_singulars/data/singulars.json',
								bounds:[1.914822,41.311855,2.364174,41.486620],
								layer:'tur_bcn'
						},
						24: {
								url:'https://tiles.urbanzeb.com/VT_singulars/data/singulars.json',
								bounds:[1.916608,41.412213,2.212460,41.527154],
								layer:'stq_com'
						},
							
					},
					territoris:{}
				}
				
			},
			{ 	key:6, 
				url:'https://tiles.urbanzeb.com/VT_Rubi/data/full', 
				urlFondo:'https://tiles.urbanzeb.com/VT_Rubi/data/geom.json',
				urlMunis:'https://tiles.urbanzeb.com/Bases/data/munis.json',
				tipo:'pro',
				numEscenarios:6,
				escenarios:[0,1,2,5,6,7],
				mapAttributes:{
					zoom:11.5,
					lng:4.1121,
					lat:39.957,
					center:[2.023819,41.501455],
					bounds:[[2.151350,41.544868],[1.875023,41.453582]]
				}, titulo:'Rubí',
				municipis:[
		        	
		        ],
		        minmaxFile: rubiLeyenda,
		        indicadores:{
		        	arq:[0,1,3,4,5,7,25],
		        	ener:[26,43,44,45,47,27,28,29,30,31,32,34,35],
		        	eco:[40,41,36,38,39,42,46],
		        	tots:[0,1,3,4,5,7,25,26,27,44,45,46,28,29,30,31,32,34,35,36,38,40,41,39,42,44,47,43,42]
				},
				logo: 'urbanzeb.svg',
				
				
			},
		]